import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-designerdatamodal',
  templateUrl: './designerdatamodal.component.html',
  styleUrls: ['./designerdatamodal.component.scss']
})
export class DesignerdatamodalComponent implements OnInit {

  json;


  constructor(
    public dialogRef: MatDialogRef<DesignerdatamodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    // Set the data for the target product component
    console.log(data);
    this.json = data;
  }

  ngOnInit() {
  }

}
