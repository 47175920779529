import { InspectorService } from '../inspector.service';
import { ParamatUniversePoint } from './paramatuniversepoint';
import { ParamatUniversePlane } from './paramatuniverseplane';
import { ParamatProjectionLine } from './paramatprojectionline';
/**
 * A straight line between two ParamatUniversePoints
 */
export class ParamatUniverseLine {
  start: ParamatUniversePoint;
  end: ParamatUniversePoint;

  observers = [];
  projections = [];

  private inspector: InspectorService;
  public readonly inspectorId: string;

  constructor(inspector: InspectorService, start: ParamatUniversePoint, end: ParamatUniversePoint) {
    this.inspector = inspector;
    this.inspectorId = this.inspector.registerConstructed(this);

    this.start = start;
    this.end = end;

    this.start.registerObserver(this, {point: 'start'});
    this.end.registerObserver(this, {point: 'end'});
  }

  /**
   * Register a component that wants to be updated when this point is changed
   */
  registerObserver(observer, eventdata) {
    this.observers.push({ observer:observer, eventdata: eventdata });
  }

  newValue(value, eventdata) {
    this.observers.forEach(item => {
      item.observer.newValue(this, item.eventdata);
    }); 
  }
 

  /**
   * Create projections of this line onto the plane and add them to the plane
   *
   * A line can be simultaneous be projected onto multiple planes.
   */
  projectOntoPlane(plane) {
    let line = new ParamatProjectionLine(plane, this) 
    this.projections.push(line);
  }
}
