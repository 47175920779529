import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-productionitemdetails',
  templateUrl: './productionitemdetails.component.html',
  styleUrls: ['./productionitemdetails.component.scss']
})
export class ProductionitemdetailsComponent implements OnInit {
  public item$: Observable<any>;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private db: AngularFirestore
    ) { 

  }

  ngOnInit() {
    let db = this.db;

    this.item$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        db.collection('productionitems').doc(params.get('id')).valueChanges()
      )
    );
  }

}
