import { InspectorService } from '../inspector.service';
import { DynamicValue } from './dynamicvalue';
import { Distance } from './distance';

/**
 * An attribute
 */
export class ParamatAttribute {
  name: string;
  type: string;
  value: DynamicValue;
  observers = [];
 
  private inspector: InspectorService;
  public readonly inspectorId: string;

  constructor(inspector: InspectorService, name: string, type: string) {
    this.inspector = inspector;
    this.inspectorId = this.inspector.registerConstructed(this);

    this.name = name;
    this.type = type;
  }

  /**
   * Converts the value to a number
   */
  getValueAsNumber(): number {
    const value = this.value.currentValue;

    switch(value.constructor.name) {
    case 'StaticValue':
      return value.value.value;
    case 'Distance':
      return value.value;
    default:
      throw new Error('Cannot getValueAsNumber from ' + value.constructor.name);
    }
  }


  /**
   * Sets the value of this attribute
   * This function will process the value based on the type of the attribute
   */
  setValue(value) {

    if(this.type === 'distance') {
      value = Distance.fromString(value); 
      value.registerObserver(this, {});
    }

    // DynamicValue is auto-observing value
    this.value = new DynamicValue(value);
  }

  /**
   * Updates the value via the web browser client
   */
  updateValueEvent(newValue: string) {
    // we assume the same unit!
    let distValue = Distance.fromString(newValue);
    this.value.updateValue(distValue);
  }

  /**
   * When the value changes, this Attribute receives an event
   */
  newValue(value, eventdata) {
    this.observers.forEach(observer => {
      observer.observer.newValue(value, observer.eventdata);
    });
  }

  registerObserver(observer, eventdata) {
    this.observers.push({observer: observer, eventdata: eventdata});
  
    observer.newValue(this.value, eventdata);
  }

  /**
   * Returns a DynamicValue that encapsulates a DynamicFunction that is half this value
   */
  half(): DynamicValue {
    if(typeof this.value === 'undefined') {
      console.log(this);
      throw new Error('Value is undefined, so half() is not possible');
    }
    
    return this.value.createHalfValue();
  }

  /**
   * Returns a DynamicValue that encapsulates a DynamicFunction that is -1 times this value
   */
  opposite(): DynamicValue {
    if(typeof this.value === 'undefined') {
      console.log(this);
      throw new Error('Value is undefined, so opposite() is not possible');
    }
    
    return this.value.createOppositeValue();
  }

  /**
   * Get the value component of this attribute
   */
  getValue(): DynamicValue {
    return this.value;
  }
}
