import { DomSanitizer, SafeResourceUrl, SafeUrl, SafeHtml} from '@angular/platform-browser';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Appliancetemplate } from '../appliancetemplate';

@Component({
  selector: 'app-appliancetemplatedetailsmanager',
  templateUrl: './appliancetemplatedetailsmanager.component.html',
  styleUrls: ['./appliancetemplatedetailsmanager.component.scss']
})
export class AppliancetemplatedetailsmanagerComponent implements OnInit {

  showSpinner: boolean = true;

  id: string;
  private sub: any;

  svgcode: SafeHtml = '';

  appliancetemplate: any;
  configuration: any;
  elementKeys: string[];
  componentKeys: string[];

  // appliance
  appliance:any;
  applianceconfiguration: any;

  // Inputs for svg-rendering
  svgselectproductionprocess: string;
  purposeproductionprocesses: string[];

  svgpurpose: string;
  purposes: string[];

  // the items (useful keys, such as mold and recess)
  svgpurposeitemkeys: string[] = [];
  svgpurposeitems: Map<string, boolean> = new Map<string, boolean>();
  svgpurposeitemssvgcode: Map<string, string> = new Map<string, string>();

  // inputs from interface
  selectedcomponent: string = "";

  constructor(private db: AngularFirestore, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
    // set default to prevent undefined
    this.configuration = {'alignment': {'elementKey': '', 'componentKey': ''}};
  }

  /**
   * Load purpose and select first productionproces for the purpose
   */
  setPurpose(purposeKey) {
    console.log(purposeKey);
    this.svgpurpose = purposeKey;
    this.purposeproductionprocesses = Object.keys(this.configuration.purposes[purposeKey].productionprocesses)
    this.setProductionprocess(this.purposeproductionprocesses[0]);
  }

  /**
   * Select a productionprocess
   * update the list of items that are available
   */
  setProductionprocess(ppKey) {
    this.svgselectproductionprocess = ppKey;

    console.log(ppKey);

    this.svgpurposeitemkeys = [];
    this.svgpurposeitems = new Map<string, boolean>();

    let itemKeys = Object.keys(this.configuration.purposes[this.svgpurpose].productionprocesses[ppKey].items);
    for(let kIdx=0, kLen=itemKeys.length; kIdx < kLen; kIdx++) {
      let itemKey = itemKeys[kIdx];
      this.svgpurposeitems[itemKey] = true;
      this.svgpurposeitemkeys.push(itemKey);
    }

    this.renderPurpose(this.svgpurpose, ppKey);
  }

  /**
   * Make the rerenderprocess update the svg
   */
  rerenderPurpose() {
    this.renderPurpose(this.svgpurpose, this.svgselectproductionprocess);
  }

  /**
   * Render a purpose for a productionprocess
   *
   * This function takes the selected items into account
   */
  renderPurpose(purposeKey, productionprocessKey) {
    const component = this;

    if(typeof component.configuration === 'undefined') {
      console.log('No configuration');
      return;
    }
    if(typeof component.configuration.purposes === 'undefined') {
      console.log('No purposes in configuration');
      return;
    }
    if(typeof component.configuration.purposes[purposeKey] === 'undefined') {
      console.log('No purposes with key ' + purposeKey + ' in configuration');
      return;
    }
    const purposeconfiguration = component.configuration.purposes[purposeKey];

    if(typeof purposeconfiguration.type == 'undefined' || purposeconfiguration.type !== 'svg') {
      console.log('Cannot process anything other than SVG');
      return;
    }

    console.log('PurposeConfiguration', purposeconfiguration);

    if(typeof purposeconfiguration.productionprocesses == 'undefined' || typeof purposeconfiguration.productionprocesses[productionprocessKey] == 'undefined') {
      console.log('Productionprocess ' + productionprocessKey + ' not found for purpose ' + purposeKey + '.');
      return;
    }

    // the position is also crucial. First we will calculate the alignment position. This is the element-component that is used from the external
    // product to align the entire set of elements in every component type. hart van het alignment
    // component wordt gebruikt om alle andere element componenten uit te lijnen.
    let alignmentElement = component.configuration.alignment.elementKey;
    let alignmentComponent = component.configuration.alignment.componentKey;

    // De alignment is niet belangrijk tijdens het renderen van een preview!

    let svgItems = [];
    let process = purposeconfiguration.productionprocesses[productionprocessKey];
    let itemKeys = Object.keys(process.items);
    for(let iIdx=0, iLen=itemKeys.length; iIdx < iLen; iIdx ++) {
      let processitem = process.items[itemKeys[iIdx]];
      svgItems = svgItems.concat(component.getElementComponents(processitem.component));
    }

    console.log('Items', svgItems);

    // Now we have a complete set of items to render
    // @todo bereken basepoint op basis van shapepoint van de alignment element component
    let basePoint = [0, 0];

    // Onderstaande is eigenlijk de rerender functie voor een combinatie van purpose en productionprocess !!! @todo
    // @todo ZORG DAT DE JUISTE KLEUR /STROKE wordt toegepast

    for(let kIdx=0, kLen=this.svgpurposeitemkeys.length; kIdx<kLen; kIdx++) {
      let purposeItemKey = this.svgpurposeitemkeys[kIdx];
      this.svgpurposeitemssvgcode[purposeItemKey] = '';

      let style: Map<string,string> = new Map<string,string>();
      let purposeItemParameters = this.configuration.purposes[this.svgpurpose].productionprocesses[productionprocessKey].items[purposeItemKey].parameters;
      let purposeItemComponentKey = this.configuration.purposes[this.svgpurpose].productionprocesses[productionprocessKey].items[purposeItemKey].component;
      let pipKeys = Object.keys(purposeItemParameters);
      for(let pipIdx=0, pipLen=pipKeys.length; pipIdx<pipLen; pipIdx++) {
        let pipKey = pipKeys[pipIdx];
        switch(pipKey) {
        case 'stroke':
        case 'stroke-dasharray':
          style.set(pipKey, purposeItemParameters[pipKey]);
          break;
        default:
          console.error('Cannot process pipKey: ' + pipKey);
        }
      }

      let svg = '';

      for(let iIdx=0, iLen=svgItems.length; iIdx<iLen; iIdx++) {
	let svgItem = svgItems[iIdx];

        if(svgItem.componentKey == purposeItemComponentKey) {
	  svg += component.getSvgString(svgItem, basePoint, style);
        }
      }

      this.svgpurposeitemssvgcode[purposeItemKey] = svg;
    }


    let totalsvg = '';
    for(let kIdx=0, kLen=this.svgpurposeitemkeys.length; kIdx<kLen; kIdx++) {
      let purposeItemKey = this.svgpurposeitemkeys[kIdx];
      if(this.svgpurposeitems[purposeItemKey]) {
        // only show selected
        totalsvg += this.svgpurposeitemssvgcode[purposeItemKey];
      }
    }

    // viewBox="min-x min-y width height"
    this.svgcode = this.sanitizer.bypassSecurityTrustHtml('<svg width="1000" height="500" viewBox="-1000 -500 2000 1000">' + totalsvg + '</svg>');
  }

  /**
   * Calculate the base position of a component
   */
  getPosition(itemPosition, center) {

    // shapepoint
    if(itemPosition.shapepoint[0] == 'middle' && itemPosition.shapepoint[1] == 'heart') {
      return [ center[0] + itemPosition.vector[0], center[1] + itemPosition.vector[1] ];
    }

    console.error('Cannot calculate position', itemPosition);
  }

  /**
   * Merge a map of style settings into a string for html
   */
  mapToCssString(items: Map<string,string>) {
    let css: string[] = [];
    items.forEach( (value:string, key:string) => {
      css.push(key + ':' + value);
    });
    return css.join(';');
  }

  /**
   * Convert svg type and parameters to svg-string
   * @param object style Key-value pairs
   */
  getSvgString(svgItem, baseHeart, style: Map<string,string>) {
    // calculate positions
    let position = this.getPosition(svgItem.position, baseHeart);

    let myStyle = new Map<string,string>(style);
    myStyle.set('fill','none');
    let styleString = this.mapToCssString(myStyle);

    switch(svgItem.type) {
    case 'roundrect':
      return '<rect x="' + position[0] + '" y="' + position[1] + '" width="' + svgItem.parameters.distx + '" height="' +
		svgItem.parameters.disty + '" rx="' + svgItem.parameters.radius + '" ry="' + svgItem.parameters.radius + '" style="' + styleString + '" />';

    case 'circle':
      return '<ellipse cx="' + position[0] + '" cy="' + position[1] + '" rx="' + svgItem.parameters.radius + '" ry="' + svgItem.parameters.radius + '" style="' + styleString + '" />';
    default:
      console.error('Unknown type of svgItem: ' + svgItem.type);
    }

    return '?';
  }

  /**
   * Get the indicated component from every element
   */
  getElementComponents(componentKey) {
    let component = this;

    let svgcomponents = [];

    for(let eIdx=0, eLen=component.elementKeys.length; eIdx<eLen; eIdx++) {
      let eKey = component.elementKeys[eIdx];
      let element = component.configuration.elements[eKey];

      if(typeof element.components[componentKey] != 'undefined') {
        let result =component.replaceParameters(element.components[componentKey]);
        // register key for selecting rendering
        result.componentKey = componentKey;
        svgcomponents.push(result);
      }
    }
    return svgcomponents;
  }

  getParameterValue(key) {
    if(typeof this.applianceconfiguration.definition.defaultvalues[key] !== 'undefined') {
       return this.applianceconfiguration.definition.defaultvalues[key]
    }
    console.log('Cannot find value for ' + key + ', returning default');

    // @todo get defaults from appliancetemplate
    return 0;
  }

  /**
   * Replace parameters to complete the componentDefinition
   */
  replaceParameters(componentDefinition) {
    let component = this;
    let fixedComponent = JSON.parse(JSON.stringify(componentDefinition));

    let parameterKeys = Object.keys(componentDefinition.parameters);

    for(let pIdx=0, pLen=parameterKeys.length; pIdx < pLen; pIdx++) {
      let parameterKey = parameterKeys[pIdx];

      let parameter = componentDefinition.parameters[parameterKey];
      switch(parameter.type) {
      case 'calculation':
        let calculation = '';

        for(let eIdx=0, eLen=parameter.elements.length; eIdx<eLen; eIdx++) {
          let celem = parameter.elements[eIdx];
          if(celem.type === 'parameter') {
            fixedComponent.parameters[parameterKey].elements[eIdx] = component.getParameterValue(celem.key);
          } else if(celem.type === 'operator') {
            fixedComponent.parameters[parameterKey].elements[eIdx] = celem.operator;
          } else if(celem.type === 'constant') {
            fixedComponent.parameters[parameterKey].elements[eIdx] = celem.value;
          } else {
            console.error('Unknown celem type: ' + celem.type);
          }
        }
        if(parameter.elements.length == 1) {
          fixedComponent.parameters[parameterKey] = fixedComponent.parameters[parameterKey].elements[0];
        } else {

          let result = 0;
          let formula = fixedComponent.parameters[parameterKey].elements.join('');

          eval("result=" + formula + ';');

          console.error('Real calculation?', fixedComponent.parameters[parameterKey], result);
          fixedComponent.parameters[parameterKey] = result;
        }
        break;
      case 'parameter':
        fixedComponent.parameters[parameterKey] = component.getParameterValue(parameter.key);
        break;
      case 'constant':
        fixedComponent.parameters[parameterKey] = parameter.value;
        break;
      default:
        throw new Error('Unknown parameter type: ' + parameter.type);
      }
    }

    // also replace in the position
    for(let pIdx=0, pLen=componentDefinition.position.vector.length; pIdx<pLen; pIdx++) {
      let posItem = componentDefinition.position.vector[pIdx];

      if(typeof posItem.type == 'undefined') {
        // a constant value
        fixedComponent.position.vector[pIdx] = posItem;
      } else {
        fixedComponent.position.vector[pIdx] = component.executeCalculationWithParameters(posItem);
      }
    }

    return fixedComponent;
  }

  executeCalculationWithParameters(calculation) {
    switch(calculation.type) {
    case 'parameter':
      return this.getParameterValue(calculation.key);
      break;
    case 'calculation':
      let value = 0, operator = null;
      for(let eIdx=0, eLen=calculation.elements.length; eIdx<eLen; eIdx++) {
        let elem = calculation.elements[eIdx];

        switch(elem.type) {
        case 'operator':
          if(operator !== null && operator != '-') {
            throw new Error('Cannot have two operators');
          }
          operator = elem.operator;
          break;
        default:
          let subvalue = this.executeCalculationWithParameters(elem);
          if(operator === null) {
            value = subvalue;
          } else {
            switch(operator) {
            case '-':
              value -= subvalue;
              break;
            case '+':
              value += subvalue;
              break;
            case '*':
              value *= subvalue;
              break;
            case '/':
              value /= subvalue;
              break;
            default:
              throw new Error('Unknown operator: ' + operator);
            }
            operator = null;
          }
        }
      }
      return value;
      break;
    case 'constant':
      return calculation.value;
    default:
      console.error('Position item type ' + calculation.type + ' unknown');
      return 0;
    }
  }

  ngOnInit() {
    let component = this;

    /**
     * Wait for the route parameter to be available
     */
    this.sub = this.route.params.subscribe(params => {
       this.id = params['id'];

       this.db.collection('appliancetemplates').doc(this.id).ref.get().then((doc) => {

         if(doc.exists) {
  	   console.log('Document', doc);
           component.appliancetemplate = doc.data();
           console.log('Data', component.appliancetemplate);
           if(typeof component.appliancetemplate.configuration !== 'undefined') {
             component.configuration = JSON.parse(component.appliancetemplate.configuration);
             component.elementKeys = Object.keys(component.configuration.elements);

             // get all available componentkeys
             component.componentKeys = Object.keys(component.configuration.components);


	     // @todo make dynamic, not static Pitt Drum
	     this.db.collection('appliances').doc('35UIKEJvIHrOKkio8rn5').ref.get().then((doc) => {
	       if(doc.exists) {
		 component.appliance = doc.data();
		 if(typeof component.appliance.configuration !== 'undefined') {
		   component.applianceconfiguration = JSON.parse(component.appliance.configuration);


		   component.purposes = Object.keys(component.configuration.purposes);
		   component.setPurpose(component.purposes[0]);

		 }
	       } else {
		 console.log('Appliance not found');
	       }
	     });
	   }

           this.showSpinner = false;
         } else {
           console.log('Document not found');
         }
       });


    });
  }

  /**
   * Respond to changes
   */
  ngOnChanges(changes: SimpleChanges) {
    console.log('Changes: ', changes);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
