import { PaperScope, Project, Path, Point, Layer, Color, Size, PointText, Segment } from 'paper';

/**
 * A projection of a cuboid. 
 *
 * Values are entered statically, they can be update with new static values
 */
export class ParamatProjectionCuboid {
  // properties of the cuboid
  private _origin: number[]     = [0,0,0];
  private _normal: number[]     = [0,0,1];
  private _north: number[]      = [0,1,0];
  private _attributes: number[] = [100,100,100];

  // Information relevant for calculating the projection and indicators
  private _projectionOrigin: number[]     = [0,0,0];
  private _projectionNormal: number[]     = [0,0,1];
  private _projectionNorth: number[]      = [0,1,0];

  // The layers of paperjs
  _currentDrawLayer: Layer = null;
  _currentClickLayer: Layer= null;

  // the calculated 3D corners
  private _corners: number[][] = new Array();
  
  // the 2D points that are the projection onto 2D
  private _points: number[][] = new Array();
  
  // The paperjs paths
  private _paths = new Array();
  private _clickPaths = new Array();

  // The unique id of the component owning this projection
  private _componentId: string = null;

  private _projectionItemsReady:boolean  = false;
  constructor(origin: number[], normal: number[], north: number[], attributes: number[]) {
    this._origin = origin;
    this._normal = normal;
    this._north = north;
    this._attributes = attributes;

    this.calculatePositions();
  }

  set componentId(componentId: string) {
    if(this._componentId !== null && this._componentId !== componentId) {
      throw new Error('ComponentId cannot be replaced on ParamatUniverseCuboid');
    }
    console.log('Setting componentId', componentId)
    this._componentId = componentId;
  }

  get componentId(): string {
    return this._componentId;
  }

  /**
   * Updates the properties and recalculates the positions 
   */
  updateProperties(origin: number[], normal: number[], north: number[], attributes: number[]) {
    this._origin = origin;
    this._normal = normal;
    this._north = north;
    this._attributes = attributes;

    this.calculatePositions();
    this.redraw();
  }


  /**
   * Updates the properties of the plane onto which we are projecting
   */
  updateProjectionPlane(origin: number[], normal: number[], north: number[]) {
    console.error('projectionplane is not used, yet');
    this._projectionOrigin = origin;
    this._projectionNormal = normal;
    this._projectionNorth = north;
  }

  /**
   * Calculates all corners based on the current state
   * The calculated corners are independent of the view-plane
   *
   */
  calculatePositions() {
    const halfDistX: number = this._attributes[0]/2;
    const halfDistY: number = this._attributes[1]/2;

    const halfDistXopposite: number = -halfDistX;
    const halfDistYopposite: number = -halfDistY;
 
    const distZtop = 0;
    const distZbottom = 0 + this._attributes[2];

    // Left/Right | Top/Bottom | Back/Front
    let cornerLTB: number[] = [halfDistXopposite, halfDistYopposite, distZtop];
    let cornerRTB: number[] = [halfDistX,         halfDistYopposite, distZtop];
    let cornerRTF: number[] = [halfDistX,         halfDistY,         distZtop];
    let cornerLTF: number[] = [halfDistXopposite, halfDistY,         distZtop];
    let cornerLBB: number[] = [halfDistXopposite, halfDistYopposite, distZbottom];
    let cornerRBB: number[] = [halfDistX,         halfDistYopposite, distZbottom];
    let cornerRBF: number[] = [halfDistX,         halfDistY,         distZbottom];
    let cornerLBF: number[] = [halfDistXopposite, halfDistY,         distZbottom];

    this._corners = [cornerLTB,cornerRTB,cornerRTF,cornerLTF,cornerLBB,cornerRBB,cornerRBF,cornerLBF];
 
    // rotate by normal around origin
    console.error('Normal is not applied to cuboid projection');

    // Apply North only in xy-plane relative to [0,1]
    const northAngleRad = Math.atan(this._north[1] / this._north[0]) - Math.PI/2;
    const northAngle = northAngleRad * 180 / Math.PI;
    const originPoint = new Point(0,0);

    this._corners.forEach(corner => { 
      let point = new Point(corner[0], corner[1]);
      const newCorner = point.rotate(northAngle, originPoint);

      corner[0] = newCorner.x;
      corner[1] = newCorner.y;
    });

    // add origin
   
    this._corners.forEach(corner => {
      corner[0] = corner[0] + this._origin[0];
      corner[1] = corner[1] + this._origin[1];
      corner[2] = corner[2] + this._origin[2];
    });
  }

  /**
   * Map the 8 corners to 2D points
   *
   * This function might optimize to only have the unique points projected
   */
  mapTo2D() {
    console.error('Projection of cuboid to 2D is not active');
 
    let points = [];

    for(let cornerIndex=0, cornersLength=this._corners.length; cornerIndex<cornersLength; cornerIndex++) {
      const corner = this._corners[cornerIndex];
      points.push([corner[0], corner[1]]);
    }

    this._points = points;
  }

  /**
   * Draw onto the canvas
   */
  drawOnLayer(drawLayer: Layer, clickLayer: Layer) {
    if(this._projectionItemsReady) {
      // first drawing has already been done
      this.redraw(); 
      return;
    }

    if(this._currentDrawLayer === null) {
      this._currentDrawLayer = drawLayer;
    }
    if(this._currentClickLayer === null) {
      this._currentClickLayer = clickLayer;
    }

    this.mapTo2D();

    this._paths = [];

    let point = new Point(this._points[0]);
    for(let pIndex=1, pLength=this._points.length; pIndex<=pLength; pIndex++) {
      let nextpoint = new Point(this._points[pIndex % pLength]);
      
      let clickData = this.calculateClickLine(point, nextpoint) 
 
console.log('clickData', clickData);
      this._currentDrawLayer.activate();
      let path = new Path.Line(point, nextpoint);
      path.strokeColor = new Color('black');
      this._paths.push(path); 

      this._currentClickLayer.activate();
      let box = new Path.Rectangle(clickData.start, clickData.size);
      box.rotate(clickData.angle, point);
      box.fillColor = new Color(0,0,0);
      this._clickPaths.push(box);

      point = nextpoint;
    }
    this._projectionItemsReady = true;
    this._currentDrawLayer.activate();
  }

  redraw() {
    if(!this._projectionItemsReady) { 
      // cannot redraw before first draw
      return;
    }
    // @todo get the current version before redraw and then use a smart updating strategy to safe time
    this.mapTo2D();

    for(let pIndex=0, pLength=this._paths.length; pIndex<pLength; pIndex++) {
      let startpoint = this._points[pIndex];
      let endpoint   = this._points[(pIndex+1) % pLength];
      
      let startpointInstance = new Point(startpoint)
      let endpointInstance = new Point(endpoint)

      let path = new Path.Line(startpointInstance, endpointInstance);
      path.visible = false;

      this._paths[pIndex].removeSegments();
      path.segments.forEach(segment => {
         this._paths[pIndex].add(segment);
      });

      let clickData = this.calculateClickLine(startpointInstance, endpointInstance)

      this._currentClickLayer.activate();

      let box = new Path.Rectangle(clickData.start, clickData.size);
      box.rotate(clickData.angle, startpointInstance);
      box.fillColor = new Color('orange');
      this._clickPaths.push(box);

      let currentItem = this._clickPaths[pIndex];
      this._clickPaths[pIndex].replaceWith(box);
      currentItem.remove();
      this._clickPaths[pIndex] = box;

      this._currentDrawLayer.activate();
    }
  }

  /**
   * Calculates start, size and angle of the clickBox for a line from startPoint to endPoint
   */
  calculateClickLine(startPoint: Point, endPoint: Point) {
       
      let lineDx = endPoint.x - startPoint.x;
      let lineDy = endPoint.y - startPoint.y;

      let lineLength = Math.sqrt(Math.pow(lineDx, 2) + Math.pow(lineDy, 2));

      // calculate rotation angle
      let lineAngle = null;
      if(Math.abs(lineDx) !== 0) { 
        if(lineDx > 0) {
      	  lineAngle = (Math.atan(lineDy / lineDx) - Math.PI / 2)* 180 / Math.PI;
        } else {
          lineAngle = (Math.atan(lineDy / lineDx) + Math.PI / 2)* 180 / Math.PI;
        }
      } else if(lineDy < 0) {
        lineAngle = 180;
      } else if(lineDy > 0) {
        lineAngle = 0;
      } else {
        throw new Error('Angle?? ' + lineDx + '  ' + lineDy);
      }

      let clickWidth = 50;
      let clickStart = new Point(startPoint.x - clickWidth, startPoint.y - clickWidth); 
      let clickSize = new Size(2*clickWidth, lineLength + 2*clickWidth); 

      return {start: clickStart, size: clickSize, angle: lineAngle};
  }
}
