import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-productionprocessesmanager',
  templateUrl: './productionprocessesmanager.component.html',
  styleUrls: ['./productionprocessesmanager.component.scss']
})
export class ProductionprocessesmanagerComponent implements OnInit {

  productionprocesses: Observable<any[]>;

  constructor(db: AngularFirestore) { 
    this.productionprocesses = db.collection('productionprocesses').valueChanges();
  }

  ngOnInit() {
  }

}
