import { Component, OnInit } from '@angular/core';
import {AuthService} from "../authentication-shared/services/auth.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  menuitems = []
  public show_logout = false;

  constructor(public auth: AuthService) {
    this.menuitems = [
      {
        routerLink: 'dashboard',
        label: 'Dashboard',
        icon: 'dashboard'
      },
      {
        routerLink : 'projects',
        label: 'Projecten',
        icon: 'assignment'
      },
      {
        routerLink : 'appliances',
        label: 'Appliances',
        icon: 'Appliances'
      },
      {
        routerLink: 'appliancetemplates',
        label: 'Appliance templates',
        icon: 'wallpaper'
      },
      {
        routerLink: 'productionitems',
        label: 'Production items',
        icon: 'storage'
      },
    ];
  }

  logout() {
    const component = this;
    component.show_logout = false;
    this.auth.logout();
    component.menuitems = [];
  }



  ngOnInit() {
    if(this.auth.isLoggedIn) {
      this.show_logout = true;
    }
  }

}
