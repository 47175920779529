import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from "@angular/fire/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { TargetproductmodalComponent } from "../modals/targetproductmodal/targetproductmodal.component";
import { DesignerdatamodalComponent } from "../modals/designerdatamodal/designerdatamodal.component";
import { ConcreteprocessmodalComponent } from "../modals/concreteprocessmodal/concreteprocessmodal.component";

@Component({
  selector: 'app-projectshow',
  templateUrl: './projectshow.component.html',
  styleUrls: ['./projectshow.component.scss']
})
export class ProjectshowComponent implements OnInit {

  project;
  label: string;
  description: string;
  designerdata;
  targetproduct;
  concreteprocess;
  displayedColumns: string[] = ['version', 'action'];

  constructor(private db: AngularFirestore,
              private route: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog) {
    this.route.params.subscribe((params) => {
      const projectId = params.id;
      this.project = this.db.collection('Projects').doc(projectId).valueChanges();
      this.project.subscribe((doc) => {

        if (typeof doc === "undefined") {
          return this.router.navigate(['/projects']);
        }

        const document = doc;

        this.label = document.label;
        this.description = document.description;
        this.designerdata = document.designerdata;
        this.targetproduct = document.targetproduct;
        this.concreteprocess = document.concreteprocess;
      })
    });
  }

  ngOnInit() {
  }

  openTargetProductModal(data) {
    const dialogRef = this.dialog.open(TargetproductmodalComponent, {
      height: 'auto',
      width: '600px',
      data: data,
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('This dialog was closed');
    });
  }

  openConcreteprocessModal(data) {
    const dialogRef = this.dialog.open(ConcreteprocessmodalComponent, {
      height: 'auto',
      width: '600px',
      data: data,
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('This dialog was closed');
    });
  }

  openTargetDesginerModalModal(data) {
    const dialogRef = this.dialog.open(DesignerdatamodalComponent, {
      height: 'auto',
      width: '600px',
      data: data,
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('This dialog was closed');
    });
  }

}
