import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../authentication-shared/services/auth.service';
import {NotificationServicesService} from "../../notification-services.service";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})

export class SignInComponent implements OnInit {

  showLoginForm = true;
  useremail : string;
  password: string;

  constructor(
    public authService: AuthService,
    private noteSvc: NotificationServicesService
  ) { }

  getNotification() {
    return this.noteSvc.getNotifictionHtml();
  }

  ngOnInit() { }

  onSignIn()
  {
    this.noteSvc.setNotification(
        '',
        ''
    );

    if(typeof this.useremail === "undefined" || this.useremail === '') {
      this.noteSvc.setNotification(
          'Geen email adress',
          'Email address kan niet leeg zijn'
      )
      return;
    }
    if(typeof this.password === "undefined" || this.password === '') {
      this.noteSvc.setNotification(
          'Geen wachtwoord',
          'Wachtwoord kan niet leeg zijn'
      )
      return;
    }
    return this.authService.SignIn(this.useremail, this.password).then(() => {

    }).catch(err => {
      this.noteSvc.setNotification(
          'Fout email en of wachtwoord',
          'Het email address en wachtwoord komen niet overeen in de database');
    });
  }

}
