import { InspectorService } from '../inspector.service';
import { ParamatUniverseComponent } from './paramatuniversecomponent';
import { ParamatUniverseView } from './paramatuniverseview';
import { ParamatUniversePlane } from './paramatuniverseplane';
import { ParamatUniversePoint } from './paramatuniversepoint';
import { ParamatUniverseVector } from './paramatuniversevector';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

// @todo move to database
const worktopConfiguration = '{"type":"component","version":"0.0.1","path":"com.produvar.components.worktop","name":"Simple worktop","description":"A simple worktop with a length, width and height","attributes":{"a0001":{"name":"length","type":"distance"},"a0002":{"name":"width","type":"distance"},"a0003":{"name":"height","type":"distance"},"a0004":{"name":"material","type":"com.produvar.types.material"},"a0005":{"name":"color","type":"com.produvar.types.color"}},"elements":{"e0001":{"type":"produvarcomponentelement","version":"0.0.1","componentPath":"com.produvar.base.components.cuboid:0.0.1","parameters":{"length":{"valueFromAttribute":"length"},"width":{"valueFromAttribute":"width"},"height":{"valueFromAttribute":"height"}}}}}';

/**
 * A Paramat Universe that can hold multiple components
 */
export class ParamatUniverse {

  // the base plane for components in this universe
  basePlane: ParamatUniversePlane;

  // the components created in this universe
  components: ParamatUniverseComponent[] = new Array();
 
  // The list of Observers that need updates on changes of this universe
  observers = new Array();

  private inspector: InspectorService;
  public readonly inspectorId: string;

  /**
   * Constructs a ParamatUniverse
   */
  constructor(inspector: InspectorService, private afs: AngularFirestore) {
    this.inspector = inspector;
    this.inspectorId = this.inspector.registerConstructed(this);

    // The plane on which the universe is positioned
    this.basePlane = ParamatUniversePlane.defaultPlane(this.inspector);

    // inform the inspector about the basePlane
    this.inspector.registerConstructedChild(this.inspectorId, 'basePlane', this.basePlane.inspectorId);
  } 

  /**
   * Register observers of this universe
   */
  registerObserver(observer, eventdata) {
    this.observers.push({observer: observer, eventdata: eventdata});
  }

  /**
   * Creates a new component in this universe
   * "com.produvar.components.worktop:0.0.1", {"length": "2000mm", "width": "600mm", "height": "40mm"});
   */
  createComponent(path, parameters) {

    // try firestore
    let simpleTopRef = this.afs.collection('library').doc('1NOO0jUEz0IDAgl7XGg9').ref;
    return simpleTopRef.get().then(snapShot => {
      if(snapShot.exists) {
        console.log('Document',snapShot.data());

        let configuration = snapShot.data();
	let newComponent = new ParamatUniverseComponent(this.inspector, this, ParamatUniversePlane.defaultPlane(this.inspector), configuration);
	 
	// inform the inspector
	this.inspector.registerConstructedChild(this.inspectorId, 'component', newComponent.inspectorId); 

	if(path === "com.produvar.components.worktop:0.0.1") {
	  if(typeof parameters.length !== 'undefined' && typeof parameters.height !== 'undefined' && typeof parameters.width !== 'undefined') {
	    newComponent.setAttributeValues(parameters);
	  }
	}
	this.components.push(newComponent);
	return newComponent; 

      } else {
        console.error('Object not found');
      }
    });
   
//    let configuration = JSON.parse(worktopConfiguration);
  }

  /**
   * Returns an array of components
   */
  getComponents() {
    return this.components;
  }

  /**
   * Creates a dynamic view into the universe
   */
  createView(plane: ParamatUniversePlane, key: string) {
    return new ParamatUniverseView(this, plane, key);
  }
}
