import { DynamicValue } from './dynamicvalue';
import { NumberValue } from './numbervalue';

/**
 * A vector is a direction and a length
 */
export class ParamatUniverseVector {
  protected dx: DynamicValue;
  protected dy: DynamicValue;
  protected dz: DynamicValue;

  // The observers
  observers = [];

  /**
   * Creates a new instance of a ParamatUniverseVector
   */
  public constructor(dx: DynamicValue, dy: DynamicValue, dz: DynamicValue) {
    this.dx = dx;
    this.dy = dy;
    this.dz = dz;

    this.dx.registerObserver(this, {attribute: 'dx'});
    this.dy.registerObserver(this, {attribute: 'dy'});
    this.dz.registerObserver(this, {attribute: 'dz'});
  }

  /**
   * Returns the current state as an array of numbers
   */
  asStaticArray(): number[] {
    return [
      this.dx.getValueAsNumber(),
      this.dy.getValueAsNumber(),
      this.dz.getValueAsNumber(),
    ];
  }

  /**
   * Updates the value of dx, dy or dz
   */
  updatePart(key, value) {
    switch(key) {
    case 'dx':
      this.dx.updateValue(value);
      break;
    case 'dy':
      this.dy.updateValue(value);
      break;
    case 'dz':
      this.dz.updateValue(value);
      break;
    default:
      throw new Error('Part "' + key + '" is unknwon on ParamatUniverseVector');
    }
  }

  /**
   * Receive change from a DynamicValue that we are observing
   */
  newValue(value, eventdata) {
    if(typeof value === 'undefined') {
      throw new Error('Cannot update to undefined value');
    }

    this.observers.forEach(observer => {
      observer.observer.newValue(this, observer.eventdata);
    });
  }

  /**
   * Registers a new observer
   */
  public registerObserver(observer, eventdata) {
    this.observers.push({observer: observer, eventdata: eventdata});
    observer.newValue(this, eventdata);
  }

  /**
   * Returns a reference to the DynamicValue of dx
   */
  getDx() {
    return this.dx;
  }
  getDy() {
    return this.dy;
  }
  getDz() {
    return this.dz;
  }

  /**
   * Checks if the direction is parallel (positive or negative)
   */
  isParallelTo(vector: ParamatUniverseVector) {
    console.error('Parallel check disabled!');
    return true;
/*
    let a = this.getUnitVector();
    let b = vector.getUnitVector();

    const eqX = a.dx.equals(b.dx);
    const eqY = a.dy.equals(b.dy);
    const eqZ = a.dz.equals(b.dz);

    return eqX && eqY && eqZ; 
*/
  }

//  /**
//   * Get this vector scaled back to length 1
//   */
//  getUnitVector() {
//    let vlength: number = this.getLength();
//    return ParamatUniverseVector.constant(this.dx.divideByNumber(vlength), this.dy.divideByNumber(vlength), this.dz.divideByNumber(vlength))
//  }
//
//  /**
//   * Returns the length of the vector
//   */
//  getLength() : number {
//    const dxValue = this.dx.getCurrentValu
//
//
//
//    const dx: number = this.dx.asNumber();
//    const dy: number = this.dy.asNumber();
//    const dz: number = this.dz.asNumber();
// 
//    return Math.sqrt(dx*dx+dy*dy+dz*dz);
//  }

  /**
   * Returns the default north vector (0,1, 0)
   */
  static defaultNorthVector() {
    return this.constant(new NumberValue(0),new NumberValue(0),new NumberValue(0));
  }

  /**
   * Returns north vector after degrees conversion
   */
  toNorthVector(deg: number) {
    let x: number = 0;
    let y: number = 0;
    if (deg <= 0) {
      x = 1;
      let tmpY: number = (deg / 180);
      y = (x / tmpY) + 1;
    } else {
      y = 1;
      let tmpX: number = (deg / 180);
      x = (y / (y - tmpX)) - 1;
    }
    let coords: [number, number];
    coords = [x, y];
    return coords;
  }

  /**
   * Returns the default up vector (0,0,1)
   */
  static defaultUpVector() {
    return this.constant(new NumberValue(0),new NumberValue(0),new NumberValue(1));
  }

  equalsDefaultUpVector() {
    return this.dx.equals(new NumberValue(0)) && this.dy.equals(new NumberValue(0)) && this.dz.equals(new NumberValue(1));
  }

  /**
   * Checks if the three components x, y and z are equal for both vectors
   */
  equals(other: ParamatUniverseVector) {
    return this.dx.equals(other.dx) && this.dy.equals(other.dy) && this.dz.equals(other.dz);
  }

  /**
   * Create a vector initialized by constants
   */
  static constant(dx: NumberValue, dy: NumberValue, dz: NumberValue): ParamatUniverseVector {
    return new ParamatUniverseVector(
      DynamicValue.constant(dx),
      DynamicValue.constant(dy),
      DynamicValue.constant(dz)
    );
  }

}
