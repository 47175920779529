import { InspectorService } from '../inspector.service';
import { Distance } from './distance';
import { DynamicValue } from './dynamicvalue';
import { DynamicValueCalculation } from './dynamicvaluecalculation';
import { ParamatUniverse } from './paramatuniverse';
import { ParamatUniversePoint } from './paramatuniversepoint';
import { ParamatUniverseLine } from './paramatuniverseline';
import { ParamatUniversePlane } from './paramatuniverseplane';
import { ParamatUniverseVector } from './paramatuniversevector';
import { ParamatAttribute } from './paramatattribute';
import { DummyObserver } from './dummyobserver';
import { ParamatUniverseCuboid } from './paramatuniversecuboid';

/**
 * A Component in a Universe
 */
export class ParamatUniverseComponent {
  // The parent Universe that contains this component
  universe: ParamatUniverse;

  // The base alignment plane of the component
  basePlane: ParamatUniversePlane;

  // The configuration read from database
  configuration = {};

  // All attributes of the component
  attributes: ParamatAttribute[] = new Array();

  private inspector: InspectorService;
  public readonly inspectorId: string;

  // The items that are projected to represent this component
  private _projectionItems = new Array();

  private _projectionItemsReady: boolean = false;
  observers = new Array();

  /**
   * Construct a ParamatUniverse component.
   */
  constructor(inspector: InspectorService, universe: ParamatUniverse, base: ParamatUniversePlane, configuration: any) {
    this.inspector = inspector;
    this.inspectorId = this.inspector.registerConstructed(this);

    // inform the inspector about the basePlane
    this.inspector.registerConstructedChild(this.inspectorId, 'basePlane', base.inspectorId);

    this.universe = universe;
    this.basePlane = base;
    this.configuration = configuration;

    this.universe.registerObserver(this, {'part': 'component universe'});
    this.basePlane.registerObserver(this, {'part': 'component basePlane'});

    // create the typed attributes based on the configuration
    if(typeof configuration.attributes !== 'undefined') {
      for(let key in configuration.attributes) {
        let attributeConfiguration = configuration.attributes[key];
        let attribute = new ParamatAttribute(this.inspector, attributeConfiguration.name, attributeConfiguration.type);

        attribute.registerObserver(this, { attributeName: attributeConfiguration.name });
        this.attributes.push(attribute);
      }
    }
  }
 
  /**
   * Returns a unique inspector Id
   */
  get id(): string {
console.log('Getting id ', this.inspectorId);
    return this.inspectorId;
  }

  /**
   * Register an observer that observes this component
   */
  registerObserver(observer, eventdata) {
    this.observers.push({observer: observer, eventdata: eventdata});
  }

  /**
   * A client event that updates a property of the position (x,y,z)
   */
  updateOriginEvent(key, value) {
    this.basePlane.updateOriginPart(key, value);
  }

  /**
   * A client event that updates a property of the normal (dx,dy,dz)
   */
  updateNormalEvent(key, value) {
    this.basePlane.updateNormalPart(key, value);
  }

  /**
   * A client event that updates a property of the north (dx,dy,dz)
   */
  updateNorthEvent(key, value) {
    this.basePlane.updateNorthPart(key, value);
  }

  /**
   * Event when attribute has been updated
   */
  newValue(value, eventdata) {
    console.log('component newValue', eventdata);

    this.observers.forEach(item => {
      item.observer.newValue(this, item.eventdata);
    });

    if(this._projectionItemsReady) {
      this.updateProjectionItems();
    }
  }

  /**
   * Set attribute values by a key value map
   */
  setAttributeValues(parameters: { [ key: string ] : string }) {
    Object.keys(parameters).forEach(parameterKey => {
      this.getAttributeByName(parameterKey).setValue(parameters[parameterKey]);
    });
  }

  /**
   * Get attribute instance by its name
   */
  getAttributeByName(name): ParamatAttribute {
    // find attribute
    let result = null;
    this.attributes.forEach( (attr) => {
      if(attr.name === name) {
        result = attr;
      }
    });
    if(result !== null) {
      return result;
    } 

    // When attribute does not exist, throw error
    throw new Error('Attribute "' + name + '" is not defined on this component');
  }


  /**
   * Calculates all important lines in 3D
   */
  getUniverseLines() : {corners: ParamatUniversePoint[], sides: ParamatUniverseLine[]} {
    const distx: ParamatAttribute = this.getAttributeByName('length'),
          disty: ParamatAttribute = this.getAttributeByName('width'),
          distz: ParamatAttribute = this.getAttributeByName('height');

    const origin = this.basePlane.getOrigin();

    // origin of universe on heart of top of shape
    const halfDistX: DynamicValue = distx.half(),
          halfDistY: DynamicValue = disty.half(),
          halfDiztZ: DynamicValue = distz.half();

    const halfDistXopposite: DynamicValue = halfDistX.createOppositeValue(),
          halfDistYopposite: DynamicValue = halfDistY.createOppositeValue();

    const distZtop: DynamicValue = DynamicValue.constant(new Distance(0, 'mm')),
          distZbottom: DynamicValue = distz.opposite();

    // Left/Right | Top/Bottom | Back/Front
    let cornerLTB: ParamatUniversePoint = new ParamatUniversePoint(this.inspector, halfDistXopposite, halfDistYopposite, distZtop);
    let cornerRTB: ParamatUniversePoint = new ParamatUniversePoint(this.inspector, halfDistX,         halfDistYopposite, distZtop);
    let cornerRTF: ParamatUniversePoint = new ParamatUniversePoint(this.inspector, halfDistX,         halfDistY,         distZtop);
    let cornerLTF: ParamatUniversePoint = new ParamatUniversePoint(this.inspector, halfDistXopposite, halfDistY,         distZtop);
    let cornerLBB: ParamatUniversePoint = new ParamatUniversePoint(this.inspector, halfDistXopposite, halfDistYopposite, distZbottom);
    let cornerRBB: ParamatUniversePoint = new ParamatUniversePoint(this.inspector, halfDistX,         halfDistYopposite, distZbottom);
    let cornerRBF: ParamatUniversePoint = new ParamatUniversePoint(this.inspector, halfDistX,         halfDistY,         distZbottom);
    let cornerLBF: ParamatUniversePoint = new ParamatUniversePoint(this.inspector, halfDistXopposite, halfDistY,         distZbottom);

    let cornerItems = [
      {corner: cornerLTB, label: 'cornerLTB'},
      {corner: cornerRTB, label: 'cornerRTB'},
      {corner: cornerRTF, label: 'cornerRTF'},
      {corner: cornerLTF, label: 'cornerLTF'},

      {corner: cornerLBB, label: 'cornerLBB'},
      {corner: cornerRBB, label: 'cornerRBB'},
      {corner: cornerRBF, label: 'cornerRBF'},
      {corner: cornerLBF, label: 'cornerLBF'},
    ];
    let corners = [
      cornerLTB,cornerRTB,cornerRTF,cornerLTF,cornerLBB,cornerRBB,cornerRBF,cornerLBF
    ];

    // Add origin position to corner
    cornerItems.forEach(item => {
      item.corner.addPoint(origin);
      console.log('[WRITEOUT]\n' + item.corner.writeOutToString(''));
    });

    // Left/Right | Top/Bottom | Back/Front
    // Top plane
    let sideLTB_RTB = new ParamatUniverseLine(this.inspector, cornerLTB, cornerRTB);
    let sideRTB_RTF = new ParamatUniverseLine(this.inspector, cornerRTB, cornerRTF);
    let sideRTF_LTF = new ParamatUniverseLine(this.inspector, cornerRTF, cornerLTF);
    let sideLTF_LTB = new ParamatUniverseLine(this.inspector, cornerLTF, cornerLTB);

    // Bottom plane
    let sideLBB_RBB = new ParamatUniverseLine(this.inspector, cornerLBB, cornerRBB);
    let sideRBB_RBF = new ParamatUniverseLine(this.inspector, cornerRBB, cornerRBF);
    let sideRBF_LBF = new ParamatUniverseLine(this.inspector, cornerRBF, cornerLBF);
    let sideLBF_LBB = new ParamatUniverseLine(this.inspector, cornerLBF, cornerLBB);

    // vertical ribs
    let sideLTB_LBB = new ParamatUniverseLine(this.inspector, cornerLTB, cornerLBB);
    let sideRTB_RBB = new ParamatUniverseLine(this.inspector, cornerRTB, cornerRBB);
    let sideRTF_RBF = new ParamatUniverseLine(this.inspector, cornerRTF, cornerRBF);
    let sideLTF_LBF = new ParamatUniverseLine(this.inspector, cornerLTF, cornerLBF);

    // Add observers for component to observe line. Each line is already observing its start and end
    sideLTB_RTB.registerObserver(this, { line: 'LTB_RTB' });
    sideRTB_RTF.registerObserver(this, { line: 'RTB_RTF' });
    sideRTF_LTF.registerObserver(this, { line: 'RTF_LTF' });
    sideLTF_LTB.registerObserver(this, { line: 'LTF_LTB' });

    sideLBB_RBB.registerObserver(this, { line: 'LBB_RBB' });
    sideRBB_RBF.registerObserver(this, { line: 'RBB_RBF' });
    sideRBF_LBF.registerObserver(this, { line: 'RBF_LBF' });
    sideLBF_LBB.registerObserver(this, { line: 'LBF_LBB' });

    sideLTB_LBB.registerObserver(this, { line: 'LTB_LBB' });
    sideRTB_RBB.registerObserver(this, { line: 'RTB_RBB' });
    sideRTF_RBF.registerObserver(this, { line: 'RTF_RBF' });
    sideLTF_LBF.registerObserver(this, { line: 'LTF_LBF' });

    let sides = [
      sideLTB_RTB, sideRTB_RTF, sideRTF_LTF, sideLTF_LTB,
      sideLBB_RBB, sideRBB_RBF, sideRBF_LBF, sideLBF_LBB,
      sideLTB_LBB, sideRTB_RBB, sideRTF_RBF, sideLTF_LBF
    ];

    return {sides: sides, corners: corners };
  }

  /**
   * Items that represent the projection of elements
   */
  createProjectionItems(plane, projectionKey) {
    const distx: ParamatAttribute = this.getAttributeByName('length'),
          disty: ParamatAttribute = this.getAttributeByName('width'),
          distz: ParamatAttribute = this.getAttributeByName('height');

    const origin = [0,0,0];
    const normal = [0,0,1];
    const north  = [0,1,0];
    const attrs  = [distx.getValueAsNumber(), disty.getValueAsNumber(), distz.getValueAsNumber()];

    // Create a static item that might be updated with static values
    let item = new ParamatUniverseCuboid(origin, normal, north, attrs );
    item.componentId = this.id;
    item.projectOntoPlane(plane, projectionKey);

    this._projectionItems.push(item);
  }

  /**
   * Update items that represent the projection of elements
   */
  updateProjectionItems() {
    const distx: ParamatAttribute = this.getAttributeByName('length'),
          disty: ParamatAttribute = this.getAttributeByName('width'),
          distz: ParamatAttribute = this.getAttributeByName('height');

    let planeProperties = this.basePlane.asStaticObject();

    const origin = planeProperties.origin;
    const normal = planeProperties.normal;
    const north  = planeProperties.north;
    const attrs  = [distx.getValueAsNumber(), disty.getValueAsNumber(), distz.getValueAsNumber()];

    // Create a static item that might be updated with static values
    this._projectionItems[0].updateProperties(origin, normal, north, attrs);
  }

  /**
   * Get shapes that represent the projection of this component on a specified plane
   * @param {string} projectionKey A unique identifier for the projection
   */
  getProjectionShapes(plane, projectionKey: string) {
    if(!plane.equals(this.basePlane)) {
      if(plane.getNormal().equals(this.basePlane.getNormal())) {
        // same normal, so no need for issues
      } else {
	console.log(plane.getNormal(), this.basePlane.getNormal());
	console.log(plane.getNormal().getDx().currentValue, this.basePlane.getNormal().getDx().currentValue);
	console.log(plane.getNormal().getDx().currentValue.equals(this.basePlane.getNormal().getDx().currentValue));

        throw new Error('How to process different planes?');
      }
    }
/*
    let items = this.getUniverseLines();

    items.corners.forEach(corner => {
      corner.projectOntoPlane(plane);
    });
    items.sides.forEach(side => {
      side.projectOntoPlane(plane)
    })
*/

    this.createProjectionItems(plane, projectionKey);
    this._projectionItemsReady = true;

    // projections of corners and lines onto plane are added.
  }

}
