import { InspectorService } from '../inspector.service';
import { ParamatUniversePoint } from './paramatuniversepoint';
import { ParamatUniverseVector } from './paramatuniversevector';
import { ParamatProjectionPoint } from './paramatprojectionpoint';
import { ParamatProjectionLine } from './paramatprojectionline';
import { ParamatProjectionCuboid } from './paramatprojectioncuboid';
import { PaperScope, Project, Path, Point, Layer, Color, Size, PointText } from 'paper';

/**
 * A plane in a universe 
 */
export class ParamatUniversePlane {
  protected origin: ParamatUniversePoint;
  protected normal: ParamatUniverseVector; 
  protected north: ParamatUniverseVector;
  
  // the things that are projected on this plane
  projections = [];
 
  // the items that want be updated when the plane is changed
  observers = [];

  private inspector: InspectorService;
  public readonly inspectorId: string;

  /**
   * Constructs a new ParamatUniversePlane
   */
  constructor(inspector: InspectorService, origin: ParamatUniversePoint, normal: ParamatUniverseVector, north: ParamatUniverseVector) {
    this.inspector = inspector;
    this.inspectorId = this.inspector.registerConstructed(this);

    this.origin = origin;
    this.normal = normal;
    this.north = north;

    this.origin.registerObserver(this, {'part': 'origin'});
    this.normal.registerObserver(this, {'part': 'normal'});
    this.north.registerObserver(this, {'part': 'north'});

  }

  newValue(value, eventdata) {
    this.informObservers();

  }

  /**
   * Returns the basic properties as arrays of numbers
   */
  asStaticObject() {
    console.error('StaticObject of plane is not up to date');
    let origin = this.origin.asStaticArray();
    let normal = this.normal.asStaticArray();
    let north = this.north.asStaticArray();

console.log('asStaticObject', origin, normal, north)

    return {
      origin: origin,
      normal: normal,
      north:  north
    }
  }

  /**
   * Updates an aspect of the origin (x, y or z)
   */
  updateOriginPart(key, value) {
    this.origin.updatePart(key, value);
  }

  /**
   * Updates an aspect of the origin (x, y or z)
   */
  updateNormalPart(key, value) {
    this.normal.updatePart(key, value);
  }

  /**
   * Updates an aspect of the origin (x, y or z)
   */
  updateNorthPart(key, value) {
    let newValue = this.north.toNorthVector(value);
    switch(key) {
      case 'dx':
        this.north.updatePart(key, newValue[0]);
        break;
      case 'dy':
        this.north.updatePart(key, newValue[1]);
        break;
    }
  }

  getNormal(): ParamatUniverseVector {
    return this.normal;
  }

  getOrigin(): ParamatUniversePoint {
    return this.origin;
  }

  informObservers() {

    this.observers.forEach(item => {
      item.observer.newValue(this, item.eventdata);  
    });

  }

  /**
   * Creates a new plane with default origin and default normal vector
   */
  static defaultPlane(inspector: InspectorService) {
    return new ParamatUniversePlane(
        inspector,
	ParamatUniversePoint.defaultOrigin(inspector), 
	ParamatUniverseVector.defaultUpVector(),
        ParamatUniverseVector.defaultNorthVector()
    );
  }

  /**
   * Used to check instead of construct default plane
   */
  equalsDefaultPlane() {
    return this.origin.equalsDefaultOrigin() && this.normal.equalsDefaultUpVector();
  }

  /**
   * Register a component that wants to be updated when this point is changed
   */
  registerObserver(observer, eventdata) {
    this.observers.push({observer:observer, eventdata:eventdata});
  }

  /**
   * Register a component that is projected onto this plane
   */
  registerProjection(projection) {
    this.projections.push(projection);
  }

  /**
   * Draw everything onto the canvas layer
   *
   * This will also provide the indicators
   *
   */
  drawOnLayer(drawLayer: Layer, clickLayer: Layer, componentIds: string[]) {
    // Now the shapes are added to the ParamatUniversePlane instances
    this.projections.forEach(item => {

console.log('Check componentId', item.componentId, componentIds, item.constructor.name);

      if(componentIds.indexOf(item.componentId) !== -1) {

	switch(item.constructor.name) {
	case 'ParamatProjectionPoint': 
	  this.drawPointOnLayer(item, drawLayer, clickLayer);
	  break;
	case 'ParamatProjectionLine':
	  this.drawLineOnLayer(item, drawLayer, clickLayer);
	  break;
	case 'ParamatProjectionCuboid':
	  this.drawCuboidOnLayer(item, drawLayer, clickLayer);
	  break;
	default:
	  throw new Error('Cannot draw "' + item.constructor.name + ' on layer');
	}

      }
    });

  }

  /** 
   * A ParamatProjectionPoint has its own relation with the paperjs entities.
   * This plane instance only facilitates the indicators for such a point.
   */
  drawPointOnLayer(point: ParamatProjectionPoint, drawLayer: Layer, clickLayer: Layer) {

    // indicators??? baseLine + projection
    // an indicator is a position on the baseLine of the indicator. There can be multiple inidcators    

    point.drawOnLayer(drawLayer, clickLayer);
  }

  drawLineOnLayer(line: ParamatProjectionLine, drawLayer: Layer, clickLayer: Layer) {
    line.drawOnLayer(drawLayer, clickLayer);
  }

  drawCuboidOnLayer(cuboid: ParamatProjectionCuboid, drawLayer: Layer, clickLayer: Layer) {
    cuboid.drawOnLayer(drawLayer, clickLayer);
  }


  /**
   * Checks if planes are equal
   *
   * Returns true when origin and normal are the same
   */
  equals(plane: ParamatUniversePlane) {
    const originEq = this.origin.equals(plane.origin);
    const normalEq = this.normal.isParallelTo(plane.normal);

    if(!originEq) {
      console.log('Origins unequal');
    }
    if(!normalEq) {
      console.log('Normals unequal');
    }

    return originEq && normalEq;
  }
}
