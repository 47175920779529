import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from "@angular/fire/firestore";
import {Observable} from "rxjs";

@Component({
  selector: 'app-projectpage',
  templateUrl: './projectpage.component.html',
  styleUrls: ['./projectpage.component.scss']
})
export class ProjectpageComponent implements OnInit {

  displayedColumns: string[] = ['label', 'description'];

  projects: Observable<any>;

  constructor(private db: AngularFirestore) {
    this.projects = this.db.collection('Projects').valueChanges();

  }

  ngOnInit() {
  }

}
