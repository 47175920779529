import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InspectorService {
  events = new Array();
  objects = new Array();
  objectKeys = new Array();

  constructor() { }

  /**
   * Generates a unique identifier
   */
  private generateIdentifier(object): string {
    const identifier: string = 'C' + this.objectKeys.length;

    this.objectKeys.push(identifier);
    this.objects.push(object);


    return identifier;
  }


  /**
   * Registers the construction of an object
   */
  public registerConstructed(object): string {
    const identifier: string = this.generateIdentifier(object);
 
    const className = object.constructor.name;
    const event = {'type': 'registerConstructed', 'data': {'className': className}, objects: [identifier]};
    console.log('INSPECTOR ' + JSON.stringify(event));
    this.events.push(event);


    return identifier;
  }

  /**
   * Registers the construction of an object as child of another
   */
  public registerConstructedChild(parentObjectIdentifier: string, childRole:string, childObjectIdentifier) {
    const event = {
      type: 'registerConstructedChild', 
      data: {'parent': parentObjectIdentifier, childRole: childRole, child: childObjectIdentifier},
      objects: [parentObjectIdentifier, childObjectIdentifier]
    };
    console.log('INSPECTOR ' + JSON.stringify(event));
    this.events.push(event);
  }
}
