import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-targetproductmodal',
  templateUrl: './targetproductmodal.component.html',
  styleUrls: ['./targetproductmodal.component.scss']
})
export class TargetproductmodalComponent implements OnInit {

  json;

  constructor(
    public dialogRef: MatDialogRef<TargetproductmodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.json = data;
  }

  ngOnInit() {
  }

}
