import { Component, OnInit } from '@angular/core';
import { ParamatUniverse } from './paramatuniverse';
import { InspectorService } from '../inspector.service';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-paramatruntime',
  templateUrl: './paramatruntime.component.html',
  styleUrls: ['./paramatruntime.component.scss']
})
export class ParamatruntimeComponent implements OnInit {

  constructor(private inspector: InspectorService, private afs: AngularFirestore) { }

  ngOnInit() {
  }


  /**
   * Creates a universe
   */
  createUniverse() {
    return new ParamatUniverse(this.inspector, this.afs);
  }
}
