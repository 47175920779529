import { InspectorService } from '../inspector.service';
import { DynamicValue } from './dynamicvalue';
import { DynamicValueCalculation } from './dynamicvaluecalculation';
import { ParamatProjectionPoint } from './paramatprojectionpoint';
import { ParamatUniverseVector } from './paramatuniversevector';
import { NumberValue } from './numbervalue';

/**
 * A 3D point in a Universe
 *
 * The x, y and z coordinates define a point in the Universe. 
 */
export class ParamatUniversePoint {
  x: DynamicValue;
  y: DynamicValue;
  z: DynamicValue;

  // the projections of this point onto planes
  projections: ParamatProjectionPoint[] = new Array();

  // items that observe changes on this element
  observers = [];

  private inspector: InspectorService;
  public readonly inspectorId: string;

  /**
   * Construct a ParamatUniversePoint
   */
  constructor(inspector: InspectorService, x: DynamicValue, y: DynamicValue, z: DynamicValue) {
    this.inspector = inspector;
    this.inspectorId = this.inspector.registerConstructed(this);

    this.x = x;
    this.y = y;
    this.z = z;

    this.x.registerObserver(this, {attribute: 'x'});
    this.y.registerObserver(this, {attribute: 'y'});
    this.z.registerObserver(this, {attribute: 'z'});
  }

  /**
   * Returns the current state as an array of numbers
   */
  asStaticArray(): number[] {
    return [
      this.x.getValueAsNumber(), 
      this.y.getValueAsNumber(), 
      this.z.getValueAsNumber(), 
    ]; 
  }

  writeOutToString(prefix) {
    let result = prefix + 'ParamatUniversePoint(\n';

    let innerPrefix = prefix + '  ';
    result += this.x.writeOutToString(innerPrefix) + ',\n';
    result += this.y.writeOutToString(innerPrefix) + ',\n';
    result += this.z.writeOutToString(innerPrefix) + ',\n';
 
    result += prefix + ')\n';
    return result;
  }
 
  getCurrentValue() {
    return [this.x.currentValue.value, this.y.currentValue.value, this.z.currentValue.value];
  }

  clone() {
    return new ParamatUniversePoint(this.inspector, this.x.clone(), this.y.clone(), this.z.clone());
  }

  /**
   * Updates the value of x, y or z
   */
  updatePart(key, value) {
    switch(key) {
    case 'x':
      this.x.updateValue(value);
      break;
    case 'y':
      this.y.updateValue(value);
      break;
    case 'z':
      this.z.updateValue(value);
      break;
    default:
      throw new Error('Part "' + key + '" is unknwon on ParamatUniversePoint');
    }
  }

  /**
   * Adds a vector to the point, effectively translating position in 3D
   *
   * This will change the current Point 
    let cornerLTB: ParamatUniversePoint = new ParamatUniversePoint(this.inspector,                                                                                              new DynamicValue(DynamicValueCalculation.calculate(origin.x, DynamicValueCalculation.OPERATOR_PLUS, halfDistXopposite)),
        new DynamicValue(DynamicValueCalculation.calculate(origin.y, DynamicValueCalculation.OPERATOR_PLUS, halfDistYopposite)),
        new DynamicValue(DynamicValueCalculation.calculate(origin.z, DynamicValueCalculation.OPERATOR_PLUS, distZtop))
    );

   */
  addPoint(point: ParamatUniversePoint): void {
    this.x.replaceValue(DynamicValueCalculation.calculate(point.x, DynamicValueCalculation.OPERATOR_PLUS, this.x.cleanCopy()));
    this.y.replaceValue(DynamicValueCalculation.calculate(point.y, DynamicValueCalculation.OPERATOR_PLUS, this.y.cleanCopy()));
    this.z.replaceValue(DynamicValueCalculation.calculate(point.z, DynamicValueCalculation.OPERATOR_PLUS, this.z.cleanCopy()));

/*

    let xObservers = this.x.unregisterObservers();

    let calculationX = DynamicValueCalculation.calculate(this.x, DynamicValueCalculation.OPERATOR_PLUS, vector.getDx()); 
    xObservers.forEach(item => {
      calculationX.registerObserver(item.observer, item.eventdata);
    })
    this.x.updateValue(calculationX);

    let yObservers = this.y.unregisterObservers();
    let calculationY = DynamicValueCalculation.calculate(this.y, DynamicValueCalculation.OPERATOR_PLUS, vector.getDy()); 
    yObservers.forEach(item => {
      calculationY.registerObserver(item.observer, item.eventdata);
    })
    this.y.updateValue(calculationY);

    let zObservers = this.z.unregisterObservers();
    let calculationZ = DynamicValueCalculation.calculate(this.z, DynamicValueCalculation.OPERATOR_PLUS, vector.getDz()); 
    zObservers.forEach(item => {
      calculationZ.registerObserver(item.observer, item.eventdata);
    })
    this.z.updateValue(calculationZ);

*/

  }

  /**
   * Subtracts a vector from the point, effectively translating position in 3D
   *
   * This also registers observers to get a directly updated value when the underlying 'other' changes
   */
  subtract(vector: ParamatUniverseVector) {
    let base = this.clone();

    let calculationX = DynamicValueCalculation.calculate(base.x, DynamicValueCalculation.OPERATOR_MINUS, vector.getDx()); 
    base.x.updateValue(calculationX);

    let calculationY = DynamicValueCalculation.calculate(base.y, DynamicValueCalculation.OPERATOR_MINUS, vector.getDy()); 
    base.y.updateValue(calculationY);

    let calculationZ = DynamicValueCalculation.calculate(base.z, DynamicValueCalculation.OPERATOR_MINUS, vector.getDz()); 
    base.z.updateValue(calculationZ);

    return base;
  }

  /**
   * Get a translation vector that translates the current point to the other point
   */
  getVectorToPoint(other: ParamatUniversePoint) : ParamatUniverseVector {
    let calculationX = DynamicValueCalculation.calculate(other.x, DynamicValueCalculation.OPERATOR_MINUS, this.x);
    let valueX = new DynamicValue(calculationX);
    
    calculationX.registerObserver(valueX, {});

    let calculationY = DynamicValueCalculation.calculate(other.y, DynamicValueCalculation.OPERATOR_MINUS, this.y);
    let valueY = new DynamicValue(calculationY);
    calculationY.registerObserver(valueY, {});

    let calculationZ = DynamicValueCalculation.calculate(other.z, DynamicValueCalculation.OPERATOR_MINUS, this.z);
    let valueZ = new DynamicValue(calculationZ);
    calculationZ.registerObserver(valueZ, {});

    return new ParamatUniverseVector(
      valueX, valueY, valueZ
    );
  }

  /**
   * Receive change from a DynamicValue that we are observing
   */
  newValue(value, eventdata) {
    if(typeof value === 'undefined') {
      throw new Error('Cannot update to undefined value');
    }

    this.observers.forEach(observer => {
      observer.observer.newValue(this, observer.eventdata);
    });
  }

  static defaultOrigin(inspector: InspectorService) {
    return new ParamatUniversePoint(
      inspector,
      DynamicValue.constant(new NumberValue(0)), 
      DynamicValue.constant(new NumberValue(0)),
      DynamicValue.constant(new NumberValue(0))
    );
  }

  /**
   * Prevents instantiation of new ParamtUniversePoint
   */
  equalsDefaultOrigin() {
    return this.x.currentValue.equals(0) && this.y.currentValue.equals(0) && this.z.currentValue.equals(0);
  }

  /**
   * Register a component that wants to be updated when this point is changed
   */
  registerObserver(observer, eventdata) {
    this.observers.push({ observer:observer, eventdata: eventdata});

    observer.newValue(this, eventdata);
  }

  /**
   * Checks if point is equal to this point
   */
  equals(point: ParamatUniversePoint) {
    const equalX = this.x.equals(point.x); 
    const equalY = this.y.equals(point.y); 
    const equalZ = this.z.equals(point.z); 

    return equalX && equalY && equalZ; 
  }

  /**
   * Creates a projection-equivalent onto the plane
   *
   * A point can be simultaneous be projected onto multiple planes.
   */
  projectOntoPlane(plane) {
    this.projections.push(new ParamatProjectionPoint(plane, this));
  }
}
