import { DynamicValue } from './dynamicvalue';
import { StaticValue } from './staticvalue';

/**
 * A calculation that is dynamically updated when the underlying values change
 */
export class DynamicValueCalculation {
  static OPERATOR_TIMES = '*';
  static OPERATOR_MINUS = '-';
  static OPERATOR_PLUS = '+';

  operator: string;
  operands: DynamicValue[] = new Array();

  currentValue: StaticValue;

  // shadows the dynamic values
  currentOperandValues: StaticValue[] = new Array();
 
  observers = [];

  /**
   * Adds an object that needs updates about value changes
   */
  registerObserver(observer, eventdata) {
    if(typeof eventdata === 'undefined') {
      throw new Error('Eventdata should be defined');
    }

    this.observers.push({observer: observer, eventdata: eventdata});

    // inform the observer about the current value
    observer.newValue(this.currentValue, eventdata);
  }

  writeOutToString(prefix) {
    let result = prefix + 'DynamicValueCalculation(\n';
    let innerPrefix = prefix + '  ';

    result += innerPrefix + this.operator + ',\n';
    
    this.operands.forEach(operand => { 
      result += operand.writeOutToString(innerPrefix) + '\n';
    });

    result += prefix + ')\n';
    return result;
  }

  /**
   * Remove the observer
   */
  unregisterObserver(observer) {
    for(let oIndex=0, oLength=this.observers.length; oIndex < oLength; oIndex++) {
      let item = this.observers[oIndex];

      if(item.observer === observer) {
        this.observers.splice(oIndex, 1);
        return;
      }
    }
  }

/*
  clone() {
    let clone = new DynamicValueCalculation();
    clone.addOperand(this.operands[0], {label:'a'});
    clone.addOperand(this.operands[1], {label:'b'});

    clone.setOperator(this.operator);
    return clone;
  }
*/
  /**
   * Observed object changed its value (an operand)
   *
   * Eventdata is used to find out which operand has changed
   */
  newValue(value: StaticValue, eventdata) {
    if(typeof value === 'undefined') {
      throw new Error('Cannot set value to undefined');
    }
    if(value.constructor.name !== 'StaticValue') {
      console.log('Value', value);
      throw new Error('Trying to use wrong type of value ' + value.constructor.name);
    }

console.log('Eventdata on calculation', eventdata);
    if(eventdata.label === 'a') {
      this.currentOperandValues[0] = value;
    } else if(eventdata.label === 'b') {
      this.currentOperandValues[1] = value;
    } else {
      throw new Error('What to do with eventdata ' + JSON.stringify(eventdata));
    }

    if(typeof this.operator === 'undefined') {
      // operator is still undefined, skip updating observers
      return;
    }

    if(typeof this.currentOperandValues[0] === 'undefined') {
      return;
    }
    if(typeof this.currentOperandValues[1] === 'undefined') {
      return;
    }

    this.recalculateValue();
  }
 
  /**
   * Recalculate the value and inform the observers
   */
  recalculateValue() {
    if(typeof this.currentOperandValues[0] === 'undefined') {
      return;
    }
    if(typeof this.currentOperandValues[1] === 'undefined') {
      return;
    }

    switch(this.operator) {
    case DynamicValueCalculation.OPERATOR_TIMES:
      this.currentValue = this.currentOperandValues[0].multiplyWith(this.currentOperandValues[1]);
      break;
    case DynamicValueCalculation.OPERATOR_PLUS:
      this.currentValue = this.currentOperandValues[0].add(this.currentOperandValues[1]);
      break;
    case DynamicValueCalculation.OPERATOR_MINUS:
      this.currentValue = this.currentOperandValues[0].subtract(this.currentOperandValues[1]);
      break;
    default:
      throw new Error('Unknown operator "' + this.operator + '"');
    }

    this.observers.forEach(item => {
      item.observer.newValue(this.currentValue, item.eventdata);
    });
  }

  getCurrentValue(): StaticValue {
    return this.currentValue;
  }

  /**
   * Adss an operand and register for its events
   */
  addOperand(operand: DynamicValue, eventdata) {
    this.operands.push(operand);

    operand.registerObserver(this, eventdata);
  }

  setOperator(operator: string) {
    this.operator = operator;
    this.recalculateValue();
  }

  /**
   * Calculates a function
   */
  static calculate(valA: DynamicValue, operator: string, valB: DynamicValue) {
    let calculation = new DynamicValueCalculation();

    calculation.addOperand(valA, {label:'a'});

    calculation.addOperand(valB, {label:'b'});
    calculation.setOperator(operator);

    return calculation;
  }
}
