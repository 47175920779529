import { ParamatProjectionCuboid } from './paramatprojectioncuboid';

/**
 * Layer between component and projection of a part of the componet
 */
export class ParamatUniverseCuboid {
  // properties of the cuboid
  private _origin;
  private _normal;
  private _north;
  private _attributes;

  // The unique identifier of the owning component
  private _componentId: string = null;

  /**
   * The projections on planes
   */
  private _projections = {};

  /**
   * Create a ParamatUniverseCuboid that can be projected on multiple planes at the same time
   */
  constructor(origin, normal, north, attributes) {
    this._origin = origin;
    this._normal = normal;
    this._north = north;
    this._attributes = attributes;
  }

  set componentId(componentId: string) {
    if(this._componentId !== null && this._componentId !== componentId) {
      throw new Error('ComponentId cannot be replaced on ParamatUniverseCuboid');
    }

    this._componentId = componentId;

    let keys = Object.keys(this._projections);
    keys.forEach(projectionKey => {
      this._projections[projectionKey].componentId = componentId;
    });
  }
  
  get componentId(): string {
    return this._componentId;
  }
  
  /**
   * updates the properties of the cuboid and informs the projections about the change
   */
  updateProperties(origin, normal, north, attributes) {
    let projectionKeys = Object.keys(this._projections);
    projectionKeys.forEach(key => {
      this._projections[key].updateProperties(origin, normal, north, attributes);
    });
  }

  /**
   * Create projections of this line onto the plane and add them to the plane
   *
   * A line can be simultaneous be projected onto multiple planes.
   * @param {string} key A unique identifier for the projection
   */
  projectOntoPlane(plane, key: string) {

    if(key in this._projections) {
      throw new Error('Projection "' + key + '" already exists');
    }
    let projection = new ParamatProjectionCuboid(this._origin, this._normal, this._north, this._attributes);
    let planeProperties = plane.asStaticObject();
    projection.updateProjectionPlane(planeProperties.origin, planeProperties.normal, planeProperties.north);
    this._projections[key] = projection;

    projection.componentId = this._componentId;
    plane.registerProjection(projection);
  }



}
