import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})

/**
 * Spinner as per https://www.youtube.com/watch?v=qR-Cj1dGgkM and http://tobiasahlin.com/spinkit/
 */
export class LoadingSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
