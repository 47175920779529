import { Distance } from './distance';

/**
 * A static value is used by DynamicValue to provide a temporary result. 
 * Such a temporary result can be replaced by new temporay result, such that it is 
 * trivial to keep all observers of a DynamicValue informed through the newValue() handler
 *
 * This wraps a typed (distance) value
 */
export class StaticValue {
  private _value = null;

  constructor(value) {
    this._value = value;
  }

  equals(value: any) {
    if(value.constructor.name === 'StaticValue') {
      value = value._value;
    }
    if(this._value.constructor.name !== value.constructor.name) {
      // types are not the same
      return false;
    }

    switch(value.constructor.name) {
    case 'number':
    case 'Number':
      return this._value === value;
    default:
      throw new Error('How to compare values of type ' + value.constructor.name);
    }
  }

  /**
   * Protector of the value property
   *
   * A StaticValue can only be initialized, not changed
   */
  set value(value) {
    throw new Error('Cannot change the value of a StaticValue');
  }

  get value() {
    return this._value;
  }

  getType(): string {
    switch(this._value.constructor.name) {
    case 'Distance':
      return 'distance';
    case 'Number':
      return 'number';
    default:
      throw new Error('Cannot process value type "' + this._value.constructor.name + '" in StaticValue')
    }
  }

  /**
   * Calculates the multiplication result
   */
  multiplyWith(other: StaticValue): StaticValue {
    return this.calculate('*', other);
  }
  add(other: StaticValue): StaticValue {
    return this.calculate('+', other);
  }
  subtract(other: StaticValue): StaticValue {
    return this.calculate('-', other);
  }

  /**
   * Calculates the multiplication result
   */
  private calculate(operand:string, other: StaticValue): StaticValue {

    // check type-compatibility
    let typeThis = this.getType();
    let typeOther= other.getType();
 
    switch(typeThis + '|' + typeOther) {
    case 'distance|number':
      let valueA = this._value.value;
      let valueB = other.value;
      let result = null;
      switch(operand) {
      case '*':
        result = valueA * valueB;
        break; 
      case '+':
        result = valueA + valueB;
        break;
      case '-':
        result = valueA - valueB;
        break;
      default:
        throw new Error('Unknown operand "' + operand + '"');
      }

      let newDist = new Distance(result, this._value.unit);
      return new StaticValue(newDist);
      break;
    case 'number|distance':
      let valueA2 = this.value;
      let valueB2 = other._value.value;
      let result2 = null;
      switch(operand) {
      case '*':
        result2 = valueA2 * valueB2;
        break; 
      case '+':
        result2 = valueA2 + valueB2;
        break;
      case '-':
        result2 = valueA2 - valueB2;
        break;
      default:
        throw new Error('Unknown operand "' + operand + '"');
      }

      let newDist2 = new Distance(result2, other._value.unit);
      return new StaticValue(newDist2);
      break;
    case 'number|number':
      switch(operand) {
      case '*':
        return new StaticValue(this._value * other._value);
        break; 
      case '+':
        return new StaticValue(this._value + other._value);
        break;
      case '-':
        return new StaticValue(this._value - other._value);
        break;
      default:
        throw new Error('Unknown operand "' + operand + '"');
      }

      break;
    default:
      throw new Error('Cannot combine ' + typeThis + ' and ' + typeOther);
    }

  }
}
