import { StaticValue } from './staticvalue';

/**
 * A value of type distance
 */
export class Distance {
  // the numeric value
  private _value: number;
  // The scale, such as mm or cm
  private _unit: string;

  private _currentValue: StaticValue;

  // The elements that observer value changes
  private observers = [];

  constructor(value: number, unit: string) {
    this._value = value;
    switch(unit) {
    case 'mm':
      this._unit = unit;
      break;
    default:
      throw new Error('Unit "' + unit + '" is unknown');
    }
  }

  clone() {
    return new Distance(this._value, this._unit);
  }

  writeOutToString(prefix) {
    let result = prefix + 'Distance(';
    result += this._value + ', ' + this._unit;
    result += prefix + ')\n';
    return result;
  }

  /**
   * Remove the observer
   */
  unregisterObserver(observer) {
    for(let oIndex=0, oLength=this.observers.length; oIndex < oLength; oIndex++) {
      let item = this.observers[oIndex];

      if(item.observer === observer) {
        this.observers.splice(oIndex, 1);
        return;
      }
    }
  }

  /**
   * Adds an observer that needs to receive value changes of this DynamicValue
   */
  registerObserver(observer, eventdata) {
    this.observers.push({observer: observer, eventdata: eventdata});

    if(typeof this._currentValue !== 'undefined') {
      // inform the observer about the current value
      observer.newValue(this._currentValue, eventdata);
    }
  }

  set value(value: number) {
    this._value = value;
    this._currentValue = new StaticValue(value);

    this.informObservers();
  }

  get value(): number {
    return this._value;
  }

  set unit(unit: string) {
    this._unit = unit;
  }

  get unit(): string {
    return this._unit;
  }

  static fromString(value: string) {
    let parts = value.match(/^([0-9]+)([a-z]+)$/);

    if(value.length !== parts[0].length) {
      throw new Error('No full distance match: "' + value + '" match "' + parts[0] + '"');
    }
    return new Distance(parseInt(parts[1]), parts[2]);
  }


  informObservers() {
    this.observers.forEach(observer => {
      observer.observer.newValue(this._currentValue, observer.eventdata);
    });
  }
}
