import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../authentication-shared/services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-appliancesmanager',
  templateUrl: './appliancesmanager.component.html',
  styleUrls: ['./appliancesmanager.component.scss']
})
export class AppliancesmanagerComponent implements OnInit {

  appliances: Observable<any[]>;

  constructor(    public authService: AuthService, db: AngularFirestore) {

    this.appliances = db.collection('appliances').valueChanges();
  }

  ngOnInit() {
  }

}
