import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-appliancetemplatesmanager',
  templateUrl: './appliancetemplatesmanager.component.html',
  styleUrls: ['./appliancetemplatesmanager.component.scss']
})
export class AppliancetemplatesmanagerComponent implements OnInit {

  appliancetemplates: Observable<any[]>;

  constructor(db: AngularFirestore) { 
    this.appliancetemplates = db.collection('appliancetemplates').valueChanges();
  }


  ngOnInit() {
  }

}
