import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PurposesmanagerComponent } from './purposesmanager/purposesmanager.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { ProductionprocessesmanagerComponent } from './productionprocessesmanager/productionprocessesmanager.component';
import { AppliancesmanagerComponent } from './appliancesmanager/appliancesmanager.component';
import { AppliancetemplatesmanagerComponent } from './appliancetemplatesmanager/appliancetemplatesmanager.component';
import { AppliancetemplatedetailsmanagerComponent } from './appliancetemplatedetailsmanager/appliancetemplatedetailsmanager.component';
import { LoadingSpinnerComponent } from './ui/loading-spinner/loading-spinner.component';
import { ProductionitemsmanagerComponent } from './productionitemsmanager/productionitemsmanager.component';

import { SignInComponent } from './authentication/sign-in/sign-in.component';
import { SignUpComponent } from './authentication/sign-up/sign-up.component';
import { DashboardComponent } from './authentication/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './authentication/verify-email/verify-email.component';
import { AuthService } from './authentication-shared/services/auth.service';

import { InspectorService } from './inspector.service';

import {
  MatToolbarModule,
  MatButtonModule,
  MatCheckboxModule,
  MatTableModule,
  MatDialogModule,
  MatSliderModule,
  MatMenuModule, MatIconModule, MatFormFieldModule, MatSelectModule, MatInputModule, MatCardModule
} from '@angular/material';

import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { HomeComponent } from './home/home.component';
import { ProductionitemdetailsComponent } from './productionitemsmanager/productionitemdetails/productionitemdetails.component';
import { ProjectpageComponent } from './projectpage/projectpage.component';
import { ProjectshowComponent } from './projectshow/projectshow.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TargetproductmodalComponent } from './modals/targetproductmodal/targetproductmodal.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DesignerdatamodalComponent } from './modals/designerdatamodal/designerdatamodal.component';
import { ConcreteprocessmodalComponent } from './modals/concreteprocessmodal/concreteprocessmodal.component';
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { Model3dComponent } from './model3d/model3d.component';

import { ParamatruntimeComponent } from './paramatruntime/paramatruntime.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    PurposesmanagerComponent,
    ProductionprocessesmanagerComponent,
    AppliancesmanagerComponent,
    AppliancetemplatesmanagerComponent,
    AppliancetemplatedetailsmanagerComponent,
    LoadingSpinnerComponent,
    ProductionitemsmanagerComponent,
    HomeComponent,
    ProductionitemdetailsComponent,
    ProjectpageComponent,
    ProjectshowComponent,
    TargetproductmodalComponent,
    NavbarComponent,
    DesignerdatamodalComponent,
    ConcreteprocessmodalComponent,
    Model3dComponent,
    ParamatruntimeComponent,
  ],
  exports: [
    ParamatruntimeComponent, 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatListModule,
    MatGridListModule,
    MatTableModule,
    FlexLayoutModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCardModule,
    MatInputModule,
    MatSliderModule,
    NgxJsonViewerModule,
  ],
  providers: [AngularFirestoreModule, AuthService, InspectorService],
  entryComponents: [
      TargetproductmodalComponent,
      DesignerdatamodalComponent,
      ConcreteprocessmodalComponent,
      ParamatruntimeComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
