import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PurposesmanagerComponent } from './purposesmanager/purposesmanager.component';
import { ProductionprocessesmanagerComponent } from './productionprocessesmanager/productionprocessesmanager.component';
import { AppliancesmanagerComponent } from './appliancesmanager/appliancesmanager.component';
import { AppliancetemplatesmanagerComponent } from './appliancetemplatesmanager/appliancetemplatesmanager.component';
import { AppliancetemplatedetailsmanagerComponent } from './appliancetemplatedetailsmanager/appliancetemplatedetailsmanager.component';
import { ProductionitemsmanagerComponent } from './productionitemsmanager/productionitemsmanager.component';
import { ProductionitemdetailsComponent } from './productionitemsmanager/productionitemdetails/productionitemdetails.component';

import { SignInComponent } from './authentication/sign-in/sign-in.component';
import { SignUpComponent } from './authentication/sign-up/sign-up.component';
import { DashboardComponent } from './authentication/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './authentication/verify-email/verify-email.component';

// Import canActivate guard services
import { AuthGuard } from './authentication-shared/guard/auth.guard';
import { SecureInnerPagesGuard } from './authentication-shared/guard/secure-inner-pages.guard';

import { HomeComponent } from './home/home.component';
import { ProjectpageComponent } from "./projectpage/projectpage.component";
import { ProjectshowComponent } from "./projectshow/projectshow.component";
import { Model3dComponent } from "./model3d/model3d.component";


const routes: Routes = [
  { path: 'purposes', component: PurposesmanagerComponent, canActivate: [AuthGuard] },
  { path: 'productionprocesses', component: ProductionprocessesmanagerComponent, canActivate: [AuthGuard] },
  { path: 'appliances', component: AppliancesmanagerComponent, canActivate: [AuthGuard] },
  { path: 'appliancetemplates', component: AppliancetemplatesmanagerComponent, canActivate: [AuthGuard] },
  { path: 'appliancetemplates/:id', component: AppliancetemplatedetailsmanagerComponent, canActivate: [AuthGuard] },
  { path: 'productionitems', component: ProductionitemsmanagerComponent, canActivate: [AuthGuard] },
  { path: 'productionitems/:id', component: ProductionitemdetailsComponent, canActivate: [AuthGuard] },
  { path: 'projects', component: ProjectpageComponent, canActivate: [AuthGuard] },
  { path: 'projects/:id', component: ProjectshowComponent, canActivate: [AuthGuard] },
  { path: 'model', component: Model3dComponent, canActivate: [AuthGuard] },

  { path: 'login', component: SignInComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'register-user', component: SignUpComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'verify-email-address', component: VerifyEmailComponent, canActivate: [SecureInnerPagesGuard] },

  {
    path: 'documenttemplates',
    loadChildren: './documenttemplates/documenttemplates.module#DocumenttemplatesModule',
    canActivate: [AuthGuard]
  },

  {
    path: 'drawings',
    loadChildren: './drawings/drawings.module#DrawingsModule',
    canActivate: [AuthGuard]
  },

  {
    path: 'components',
    loadChildren: './componenteditor/componenteditor.module#ComponenteditorModule',
    canActivate: [AuthGuard]
  },

  {
    path: 'designer',
    loadChildren: './designer/designer.module#DesignerModule',
    canActivate: [AuthGuard]
  },

  {
    path: 'universe',
    loadChildren: './universe/universe.module#UniverseModule',
    canActivate: [AuthGuard]
  },

  { path: '', pathMatch: 'full', canActivate: [AuthGuard], redirectTo: '/dashboard' },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
  { path: '', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
