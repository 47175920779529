import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-purposesmanager',
  templateUrl: './purposesmanager.component.html',
  styleUrls: ['./purposesmanager.component.scss']
})
export class PurposesmanagerComponent implements OnInit {

  purposes: Observable<any[]>;

  constructor(db: AngularFirestore) { 
    this.purposes = db.collection('purposes').valueChanges();    
  }

  ngOnInit() {
  }

}
