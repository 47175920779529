import { ParamatUniversePoint } from './paramatuniversepoint';
import { ParamatUniverseLine } from './paramatuniverseline';
import { ParamatUniversePlane } from './paramatuniverseplane';
import { PaperScope, Project, Path, Point, Layer, Color, Size, PointText, Segment } from 'paper';

/**
 * Projection of a 3D line (a straight line between two ParamatUniversePoints) onto a plane. This is a 2D item with multi directional updateability
 *
 * A projection line will also handle the indicator-projections onto lines in the plane
 */
export class ParamatProjectionLine {
  private _currentStartX: number = null;
  private _currentStartY: number = null;
  private _currentEndX: number = null;
  private _currentEndY: number = null;

  // references the paperjs layers used
  currentDrawLayer: Layer = null;
  currentClickLayer: Layer = null;
  // a cache for objects used during drawing
  drawCache: { [id: string] : any } = new Array();

  // @todo adhere to the StaticValue convenstion
  getCurrentValue() {
    return {start: [this._currentStartX, this._currentStartY], end: [this._currentEndX, this._currentEndY]};
  }

  // the plane that the line is projected onto
  plane: ParamatUniversePlane;
  
  // the line to project
  line: ParamatUniverseLine;

  constructor(plane: ParamatUniversePlane, line: ParamatUniverseLine) {
    this.plane = plane;
    this.line = line;

    this.line.registerObserver(this, {part: 'line'});
    this.plane.registerObserver(this, {part: 'plane ptojection line'});
    this.plane.registerProjection(this);
  }
  
  newValue(value, eventdata) {
    this.redraw()
  }

  /**
   * do the actual mapping/projecting onto the plane
   */
  mapPointsToPlane() {
    if(typeof this.line.start.x.currentValue === 'undefined') {
      // start x not available, yet
      return;
    }
    if(typeof this.line.start.y.currentValue === 'undefined') {
      // start y not available, yet
      return;
    }
    if(typeof this.line.start.z.currentValue === 'undefined') {
      // start z not available, yet
      return;
    }

    if(typeof this.line.end.x.currentValue === 'undefined') {
      // end x not available, yet
      return;
    }
    if(typeof this.line.end.y.currentValue === 'undefined') {
      // end y not available, yet
      return;
    }
    if(typeof this.line.end.y.currentValue === 'undefined') {
      // end y not available, yet
      return;
    }

    let startPointX = this.line.start.x.currentValue.value.value;
    let startPointY = this.line.start.y.currentValue.value.value;
    let startPointZ = this.line.start.z.currentValue.value.value;


if(typeof startPointX === 'undefined') {
  console.log(this.line.start.x.currentValue);
//  throw new Error('StartPointX is undefined');
   startPointX = this.line.start.x.currentValue.value;
}
if(typeof startPointY === 'undefined') {
  console.log(this.line.start.y.currentValue);
//  throw new Error('StartPointY is undefined');
   startPointY = this.line.start.y.currentValue.value;
}
    let endPointX = this.line.end.x.currentValue.value.value;
    let endPointY = this.line.end.y.currentValue.value.value;
    let endPointZ = this.line.end.z.currentValue.value.value;


// @todo problemen oplossen!
if(typeof endPointX === 'undefined') {
  console.log(this.line.end.x.currentValue);
  endPointX = this.line.end.x.currentValue.value;
}

if(typeof endPointY === 'undefined') {
  console.log(this.line.end.y.currentValue);
   endPointY = this.line.end.y.currentValue.value;
}


    if(!this.plane.equalsDefaultPlane()) {
/*      let planeOriginX = this.plane.origin.x.currentValue.value;
      let planeOriginY = this.plane.origin.y.currentValue.value;
      let planeOriginZ = this.plane.origin.z.currentValue.value;

      let planeNormalDx = this.plane.normal.dx.currentValue.value;
      let planeNormalDy = this.plane.normal.dy.currentValue.value;
      let planeNormalDz = this.plane.normal.dz.currentValue.value;
  */
      throw new Error('Cannot project onto plane');
    }

    // easiest solution, the default plane with negative y-axis as upVector
    this._currentStartX = startPointX;
    this._currentStartY = startPointY;

    this._currentEndX = endPointX;
    this._currentEndY = endPointY;
  }

  redraw() {
    if(this.currentDrawLayer === null) {
      // cannot redraw without first draw
      return;
    }
    if(this.currentClickLayer === null) {
      // cannot redraw without first draw
      return;
    }

    this.drawOnLayer(this.currentDrawLayer, this.currentClickLayer)
  }

  /**
   * Draw onto the canvas
   */
  drawOnLayer(drawLayer: Layer, clickLayer: Layer) {
    if(this.currentDrawLayer === null) {
      this.currentDrawLayer = drawLayer;
    }
    if(this.currentClickLayer === null) {
      this.currentClickLayer = clickLayer;
    }

    this.mapPointsToPlane();

    let clickWidth = 50;
    let dx = Math.abs(this._currentEndX - this._currentStartX);
    let dy = Math.abs(this._currentEndY - this._currentStartY);
    let lineLength = Math.sqrt(dx*dx+dy*dy);

    if(lineLength === 0) {
      // the projection of this line from 2D onto 3D is zero length, for instance a line 
      // parallel to the normal vector

      // The projection should be a point, but for now, we don't draw
      return;
    }

    if(this.drawCache.startPoint) {
      this.drawCache.startPoint.x = this._currentStartX;
      this.drawCache.startPoint.y = this._currentStartY
    } else {
      this.drawCache.startPoint = new Point(this._currentStartX, this._currentStartY);
    }

    if(this.drawCache.endPoint) {
      this.drawCache.endPoint.x = this._currentEndX;
      this.drawCache.endPoint.y = this._currentEndY;
    } else {
      this.drawCache.endPoint = new Point(this._currentEndX,this._currentEndY);
    }

    if(this.drawCache.midPoint) {
      this.drawCache.midPoint.x = this._currentStartX+lineLength/2;
      this.drawCache.midPoint.y = this._currentStartY;
    } else {
      this.drawCache.midPoint = new Point(this._currentStartX+lineLength/2, this._currentStartY);
    }
   
    if(this.drawCache.clickStart) {
      this.drawCache.clickStart.x = this._currentStartX - clickWidth;
      this.drawCache.clickStart.y = this._currentStartY - clickWidth;
    } else {
      this.drawCache.clickStart = new Point(this._currentStartX - clickWidth, this._currentStartY - clickWidth); 
    }
 
    if(this.drawCache.clickSize) {
      this.drawCache.clickSize.width = 2*clickWidth + lineLength;
      this.drawCache.clickSize.height= 2*clickWidth;
    } else {
      this.drawCache.clickSize = new Size(2*clickWidth + lineLength, 2*clickWidth);
    }

    let lineAngle = null;

    if(dx === 0) {
      if(this._currentEndY > this._currentStartY) {
        lineAngle = 90; 
      } else {
        lineAngle = 270;
      }
    } else if(dy === 0) {
      if(this._currentEndX > this._currentStartX) {
        lineAngle = 0;
      } else {
        lineAngle = 180;
      }
    } else {
      lineAngle = Math.atan(Infinity) * 180 / Math.PI;
    }

    drawLayer.activate();

    if(this.drawCache.path) {
      let newLine = new Path.Line(this.drawCache.startPoint, this.drawCache.endPoint);
      newLine.visible = false;      

      // Update the path by replacing its segments
      this.drawCache.path.removeSegments();
      newLine.segments.forEach(segment => {
        this.drawCache.path.add(segment);
      });

    } else {
      this.drawCache.path = new Path.Line(this.drawCache.startPoint, this.drawCache.endPoint);
      this.drawCache.path.strokeColor = new Color('blue')
    }

    clickLayer.activate();

    // add the box to click on
    if(this.drawCache.box) {
      let newPath = new Path.Rectangle(this.drawCache.clickStart, this.drawCache.clickSize);
      newPath.visible = false
      newPath.rotate(lineAngle, this.drawCache.startPoint);

      this.drawCache.box.removeSegments();
      newPath.segments.forEach(segment => {
        this.drawCache.box.add(segment);
      });
    } else {
      this.drawCache.box = new Path.Rectangle(this.drawCache.clickStart, this.drawCache.clickSize);
      this.drawCache.box.rotate(lineAngle, this.drawCache.startPoint);
      this.drawCache.box.fillColor = new Color(0,0,0);

      /** 
       * A handler for interacting with the underlying path
       */
      this.drawCache.box.onClick = function(event) {
	console.log('CLICK ParamatProjectionLine ', event)
      };
    }

  }

}
