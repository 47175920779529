import { ParamatUniversePlane } from './paramatuniverseplane';
import { ParamatUniverse } from './paramatuniverse';
import { PaperScope, Project, Path, Point, Layer, Color, Size, PointText } from 'paper';
import { ParamatUniverseComponent } from './paramatuniversecomponent';

/**
 * A view in a universe 
 *
 * This can be an intersection or projection of components on a plane.
 */
export class ParamatUniverseView {
  // The parent universe
  universe: ParamatUniverse;

  // a unique name for the view
  private key: string;

  // the plane on which is being projected
  plane: ParamatUniversePlane;


  _currentDrawLayer: Layer = null;
  _currentClickLayer: Layer = null;

  constructor(universe: ParamatUniverse, plane: ParamatUniversePlane, key: string) {
    this.universe = universe;
    this.plane = plane;
    this.key = key;

    this.plane.registerObserver(this, {'part': 'plane of view'});
    this.universe.registerObserver(this, {'part': 'universe of view'});
  }
 
  newValue(value, metadata) {
    console.log('Updated view', value, metadata);
  }

  /**
   * Update, for instance, when a new component has been added
   */ 
  updateForNewComponent(component: ParamatUniverseComponent) {
    component.getProjectionShapes(this.plane, this.key);
    this.plane.drawOnLayer(this._currentDrawLayer, this._currentClickLayer, [component.id]);
  }

  /**
   * Draw this view on the indicated layer
   */
  drawOnLayer(drawLayer: Layer, clickLayer: Layer) {
    if(this._currentDrawLayer === null) {
      this._currentDrawLayer = drawLayer;
    }
    if(this._currentClickLayer === null) {
      this._currentClickLayer = clickLayer;
    }

    let componentsToProject = this.universe.getComponents();
    let componentIds: string[] = [];

    componentsToProject.forEach(component => {
      component.getProjectionShapes(this.plane, this.key);
      componentIds.push(component.id);
    });


    this.plane.drawOnLayer(drawLayer, clickLayer, componentIds);
/*
    // This code adds a bigger clicklayer on top of a draw layer to make manipulating of items easier.
    let x = 20, y = 20;
    let startPoint = new Point(x, y);
    let dx = 80;
    let dy = 80;
    let lineLength = Math.sqrt(dx*dx+dy*dy);

    let endPoint = new Point(x+dx,y+dy);
    let midPoint = new Point(x+lineLength/2, y)
   
    let clickWidth = 10;
    let clickStart = new Point(x - clickWidth, y - clickWidth); 
    let clickSize = new Size(2*clickWidth + lineLength, 2*clickWidth);
    let lineAngle = Math.atan(dy/dx) * 180 / Math.PI;

    drawLayer.activate();

    let path = new Path.Line(startPoint, endPoint);
    path.strokeColor = new Color('red');

    let text = new PointText(midPoint);
    text.fillColor = new Color('red');
    text.content = '' + lineLength.toFixed(1);
    text.rotate(lineAngle, startPoint);


    clickLayer.activate();

    // add the box to click on
    let box = new Path.Rectangle(clickStart, clickSize);
    box.rotate(lineAngle, startPoint);
    box.fillColor = new Color(0,0,0, 1);

    drawLayer.activate();

    box.onClick = function(event) {
      console.log('CLICK', event)
    };
*/
  }
}
