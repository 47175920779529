import { DynamicValueCalculation } from './dynamicvaluecalculation';
import { StaticValue } from './staticvalue';
import { NumberValue } from './numbervalue';

/**
 * A DynamicValue is a value that can be linked to other values, directly or by calculations.
 *
 * It will keep its observers up to date when values are changing
 */
export class DynamicValue {
  // The currently calculated value
  private _currentValue: StaticValue;  

  // The value that is given to this dynamicValue (for instance, a calculation)
  protected value: any;
  protected observers = [];
  
  /**
   * Constructs a new DynamicValue instance
   *
   * This constructor registers this new DynamicValue as observer of the value
   */
  constructor(value: any) {
    switch(value.constructor.name) {
    case 'Distance':
    case 'NumberValue':
    case 'DynamicValueCalculation':
      value.registerObserver(this, {'label': 'value of type ' + value.constructor.name});
      break;
    case 'Number':
      throw new Error('Number cannot be wrapped in DynamicValue'); 
    default:
      console.error('Type of DynamicValue input ' + value.constructor.name);
    }



    this.value = value;
    this.calculateCurrentValue();
  }

  writeOutToString(prefix) {
    let result = prefix + 'DynamicValue(\n';
 
    let innerPrefix = prefix + '  ';

    switch(this.value.constructor.name) {
    case 'Distance':
    case 'DynamicValueCalculation':
    case 'NumberValue':
      result += this.value.writeOutToString(innerPrefix) + '\n';
      break; 
    default:
      throw new Error('how to writeOutToString of ' + this.value.constructor.name);
    }

    result += prefix + ')';
    return result;
  }

  /**
   * A copy without observers attached
   */
  cleanCopy() {
    return new DynamicValue(this.value);
  }

  clone() {
    switch(this.value.constructor.name) {
    case 'number':
    case 'Number':
      return new DynamicValue(this.value);
    case 'Distance':
    case 'DynamicValueCalculation':
      return new DynamicValue(this.value.clone());
    default:
      throw new Error('Cannot process clone of "' + this.value.constructor.name + '"');
    }
  }

  calculateCurrentValue() {
    let value = this.value;

    switch(value.constructor.name) {
    case 'NumberValue':
      // simply copy the value
      this._currentValue = value.getCurrentValue(); 
      break;
    case 'Distance':
      this._currentValue = new StaticValue(value);
      break;
    case 'DynamicValueCalculation':
      this._currentValue = value.getCurrentValue();
      break;
    default:
      console.error('Cannot process "' + value + '" of type "' + value.constructor.name);
    }
  }

  /**
   * Converts the value to a number
   */
  getValueAsNumber(): number {
    const value = this._currentValue;

    switch(value.constructor.name) {
    case 'StaticValue':
      return value.value;
    case 'Distance':
      return value.value;
    default:
      throw new Error('Cannot getValueAsNumber from ' + value.constructor.name);
    }
  }


  /**
   * Remove the observer
   */
  unregisterObserver(observer) {
    for(let oIndex=0, oLength=this.observers.length; oIndex < oLength; oIndex++) {
      let item = this.observers[oIndex];

      if(item.observer === observer) {
        this.observers.splice(oIndex, 1);
        return;
      }
    }
  }

  /**
   * Replace the current value
   */
  replaceValue(newValue) {
    this.value.unregisterObserver(this);

    switch(newValue.constructor.name) {
    case 'Distance':
    case 'NumberValue':
    case 'DynamicValueCalculation':
      newValue.registerObserver(this, {'label': 'value of type ' + newValue.constructor.name});
      break;
    case 'Number':
      throw new Error('Number cannot be wrapped in DynamicValue');
    default:
      console.error('Type of DynamicValue input ' + newValue.constructor.name);
    }

    this.value = newValue;
    this.calculateCurrentValue();
  }

  /**
   * Updates the value, for instance, through a client event
   *
   * An attribute can call this function
   */
  updateValue(newValue: any) {
    // @todo this.value unregister all relations and then delete


    let replaceKey = this.value.constructor.name + '|' + newValue.constructor.name
    replaceKey = replaceKey.toLowerCase()

    switch(replaceKey) {
    case 'numbervalue|number':
      this.value.replaceWithNumber(newValue);
      break;
    case 'number|number':
      this.value = newValue;      
      break;
    case 'distance|distance':
      this.value.value = newValue.value;
      this.value.unit = newValue.unit;
      break;
    case 'dynamicvaluecalculation|dynamicvaluecalculation':
      this.value = newValue
      break;
    case 'distance|dynamicvaluecalculation':
      this.value = newValue
      break;
    default:
      throw new Error('how to handle replaceKey "' + replaceKey + '"');
    }

    this.calculateCurrentValue()

    this.observers.forEach(observer => {
      observer.observer.newValue(this._currentValue, observer.eventdata);
    });

    // PROBLEEM: als wij deze activeren dan schijnt er een circulaire dependency te ontstaan, waardoor de callstack helemaal vol loopt.
    //newValue.registerObserver(this, {'message': 'updateValue into dynamicvalue'});
  }

  /**
   * Only a getter for the current value
   */
  get currentValue(): StaticValue {
    return this._currentValue;
  }
 
  set currentValue(value) {
    throw new Error('Cannot set currentValue directly');
  }

  /**
   * Adds an observer that needs to receive value changes of this DynamicValue
   */
  registerObserver(observer, eventdata) {
    this.observers.push({observer: observer, eventdata: eventdata});

    if(typeof this._currentValue !== 'undefined') {
      if(this._currentValue.constructor.name === 'StaticValue') {
        // inform the observer about the current value, if that value is available
        observer.newValue(this._currentValue, eventdata);
      } else {
        throw new Error('Value should be of type StaticValue, not ' + this._currentValue.constructor.name);
      }
    }
  }
 
  /**
   * Removes all observer and returns them, such that the observers can be attached to another object
   */
  unregisterObservers() {
    const oldObservers = this.observers;
    this.observers = new Array();
    return oldObservers;
  }

  /**
   * Observer function for receiving a new value
   */
  newValue(value: StaticValue, eventdata) {
    if(value.constructor.name !== 'StaticValue') {
      throw new Error('newValue should be of type "StaticValue", not "' + value.constructor.name + '"');
    }

    this._currentValue = value;
    this.observers.forEach(observer => {
      observer.observer.newValue(this._currentValue, observer.eventdata);
    });
  }

  /**
   * Wraps a discrete value
   */
  static constant(value: any): DynamicValue {
    return new DynamicValue(value);
  }

  /**
   * Returns true when the values are the same
   */
  equals(value) {

    switch(value.constructor.name) {
    case 'NumberValue':
    case 'DynamicValue':
      return this.currentValue.equals(value.currentValue);
    default:    
      throw new Error('How to compare ' + value.constructor.name + '??');
    }
  }

  /**
   * Divides the current value through the val
   */
/*  divideByNumber(val: number): number {
    
      

    let base: number = this._currentValue();
    return base / val;
  }
*/
  /**
   * Returns the value as number
   */
/*  asNumber() : number {
    let base: number;

    switch(this.value.constructor.name) {
    case 'Number':
      base = this.value;
      break;
    default:
      throw new Error('Cannot make number of "' + this.value.constructor.name + '"');
    }

    return base;
  }
*/

  /**
   * Returns a new DynamicValue that encalsulates a DynamicValueCalculation that represents half of this value
   */
  createHalfValue(): DynamicValue {
    let calculation = DynamicValueCalculation.calculate(this, DynamicValueCalculation.OPERATOR_TIMES, DynamicValue.constant(new NumberValue(0.5)));
    let value = new DynamicValue(calculation);

    return value;
  }


  /**
   * Returns a DynamicValue that encalsulates a DynamicValueCalculation that represents this value times -1
   */
  createOppositeValue(): DynamicValue {
    let calculation = DynamicValueCalculation.calculate(this, DynamicValueCalculation.OPERATOR_TIMES, DynamicValue.constant(new NumberValue(-1)));
    let value = new DynamicValue(calculation);

    return value;
  }
}
