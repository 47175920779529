import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-concreteprocessmodal',
  templateUrl: './concreteprocessmodal.component.html',
  styleUrls: ['./concreteprocessmodal.component.scss']
})
export class ConcreteprocessmodalComponent implements OnInit {

  json;

  constructor(
    public dialogRef: MatDialogRef<ConcreteprocessmodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {

    console.log(data);
    // Set the data for the target product component
    this.json = data;
  }

  ngOnInit() {
  }

}
