import { StaticValue } from './staticvalue';

/**
 * An observable wrapper for numbers
 */
export class NumberValue {
  _numberValue: number;
  _currentValue: StaticValue;

  observers = [];

  constructor(value) {
    switch(value.constructor.name) {
    case 'number':
    case 'Number':
      break;
    default:
      throw new Error('NumberValue cannot wrap "' + value.constructor.name + '"');
    }
    this._numberValue = value;
    this._currentValue = new StaticValue(value);
  }

  /**
   * Registers an observer of the value of this NumberValue
   */
  registerObserver(observer, eventdata) {
    this.observers.push({
      observer: observer,
      eventdata: eventdata
    });

    observer.newValue(this._currentValue, eventdata);
  }

  informObservers() {
    this.observers.forEach(item => {
      item.observer.newValue(this._currentValue, item.eventdata);
    }); 
  }


  writeOutToString(prefix) {
    let result = prefix + 'DynamicValue(';
    result += this._numberValue;
    result += ')';
    return result;
  }

  /**
   * Replaces the current value and instructs all observers to handle the new value
   */
  replaceWithNumber(newValue: number) {
    console.log('Replace value ' + this._numberValue + ' with ' + newValue);
    this._numberValue = newValue;
    this._currentValue = new StaticValue(newValue);
    
    this.informObservers();
  }

  getCurrentValue() {
    return this._currentValue;
  }

  /**
   * Getter for the current value
   */
  get currentValue(): StaticValue {
    return this._currentValue;
  }
}
