import { ParamatUniversePoint } from './paramatuniversepoint';
import { ParamatUniversePlane } from './paramatuniverseplane';
import { PaperScope, Project, Path, Point, Layer, Color, Size, PointText } from 'paper';

/**
 * Projection of a 3D point onto a plane. This is a 2D item with multi directional updateability
 *
 * A projection point will also handle the indicator-projections onto lines in the plane
 */
export class ParamatProjectionPoint {
  private _currentX: number = null;
  private _currentY: number = null;

  // the plane that the point is projected on
  plane: ParamatUniversePlane;
  
  // the point to project
  point: ParamatUniversePoint;

  // a cache for objects used during drawing
  drawCache: { [id: string] : any } = new Array();

  // references the paperjs layers used
  currentDrawLayer: Layer = null;
  currentClickLayer: Layer = null;

  constructor(plane: ParamatUniversePlane, point: ParamatUniversePoint) {
    this.plane = plane;
    this.point = point;

    this.point.registerObserver(this, {element: 'point'});
    this.plane.registerObserver(this, {element: 'plane projection point'});
    this.plane.registerProjection(this);
  }

  newValue(value, eventdata) {
    this.redraw();
  }

  /**
   * do the actual mapping/projecting onto the plane
   */
  mapPointToPlane() {
    if(typeof this.point.x.currentValue === 'undefined') {
      // value for x is not available, yet
      return;
    }

    if(typeof this.point.y.currentValue === 'undefined') {
      // value for y is not available, yet
      return;
    }

    if(typeof this.point.z.currentValue === 'undefined') {
      // value for z is not available, yet
      return;
    }

    let pointX = this.point.x.currentValue.value.value;
    let pointY = this.point.y.currentValue.value.value;
    let pointZ = this.point.z.currentValue.value.value;

    if(typeof pointX === 'undefined') {
      console.log(this.point.x.currentValue);
    //  throw new Error('PointX is undefined');
       pointX = this.point.x.currentValue.value;
    }

    if(typeof pointY === 'undefined') {
      console.log(this.point.y.currentValue);
    //  throw new Error('PointY is undefined');
       pointX = this.point.y.currentValue.value;
    }

    if(!this.plane.equalsDefaultPlane()) {
/*      let planeOriginX = this.plane.origin.x.currentValue.value;
      let planeOriginY = this.plane.origin.y.currentValue.value;
      let planeOriginZ = this.plane.origin.z.currentValue.value;

      let planeNormalDx = this.plane.normal.dx.currentValue.value;
      let planeNormalDy = this.plane.normal.dy.currentValue.value;
      let planeNormalDz = this.plane.normal.dz.currentValue.value;
  */    
      throw new Error('Cannot project onto plane');
    }

    // easiest solution, the default plane with negative y-axis as upVector
    this._currentX = pointX;
    this._currentY = pointY;
  }

  redraw() {
    if(this.currentDrawLayer !== null && this.currentClickLayer !== null) {
      this.drawOnLayer(this.currentDrawLayer, this.currentClickLayer);
    }
  }

  /**
   * Draw onto the canvas
   */
  drawOnLayer(drawLayer: Layer, clickLayer: Layer) {
    if(this.currentDrawLayer === null) {
      this.currentDrawLayer = drawLayer;
    }
    if(this.currentClickLayer === null) {
      this.currentClickLayer = clickLayer;
    }

    this._currentX = 100 + Math.floor(100*Math.random());
    this._currentY = 100 + Math.floor(100*Math.random());

    this.mapPointToPlane();

    if(this.drawCache.point) {
      this.drawCache.point.x = this._currentX;
      this.drawCache.point.y = this._currentY;
    } else {
      this.drawCache.point = new Point(this._currentX, this._currentY);
    }

    drawLayer.activate();
    if(this.drawCache.path) {
      this.drawCache.path.position = this.drawCache.point;
    } else {
      this.drawCache.path = new Path.Circle(this.drawCache.point, 1);
      this.drawCache.path.strokeColor = new Color('green') 
    }

    clickLayer.activate();

    if(this.drawCache.clickPath) {
      this.drawCache.clickPath.position = this.drawCache.point;
    } else {
      this.drawCache.clickPath = new Path.Circle(this.drawCache.point, 10);
      this.drawCache.clickPath.strokeColor = new Color('black');
      this.drawCache.clickPath.fillColor = this.drawCache.clickPath.strokeColor;

      /** 
       * A handler for interacting with the underlying path
       */
      this.drawCache.clickPath.onClick = function(event) {
	console.log('CLICK ParamatProjectionPoint ', event)
      };
    }


  }
}
